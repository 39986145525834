import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface EditInvoiceProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditInvoice extends React.Component<EditInvoiceProps> {

    @observable private invoice: any;
    @observable private isLoaded = false;
    @observable private id: any = "";
    @observable private redirect: boolean = false;
    @observable private validationErrors: string[] = [];
    @observable private statusOptions: any = [{
        name: "Pendiente", id: "Pendiente"
    }, {
        name: "Pagado", id: "Pagado"
    }, {
        name: "Pendiente Desconexion", id: "Pendiente Desconexion"
    }, {
        name: "Vencido", id: "Vencido"
    }, {
        name: "Cancelado", id: "Cancelado"
    }];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        this.id = id;
        http.get(`/api/invoice/read/${id}`).then((response) => {
            this.invoice = response.data;
            this.isLoaded = true;
        })
            .catch(() => {
                toast.error("Error cargando Recibo");
            });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.invoice[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {

        this.invoice.amount = this.invoice.amount.replace("$", '').replace(/,/g, '');
        this.invoice.updated_by = sessionService.getUserInfo().id;

        http.post(`/api/invoice/update`, JSON.stringify(this.invoice))
            .then(() => {
                toast.success(`Recibo actualizado`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/recibos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Recibo: {this.invoice.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../recibos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="status"
                                    label="Estado del recibo"
                                    value={this.invoice.status}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.statusOptions}
                                    disabled={false}
                                />
                                <InputForm
                                    name="amount"
                                    label="Cantidad del recibo"
                                    value={this.invoice.amount}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    prefix="$"
                                    decimal={2}
                                    thousandSeparator={true}
                                    disabled={false}
                                />
                                {this.invoice.due_date &&
                                    <InputForm
                                        name="due_date"
                                        label="Fecha de Vencimiento (año/mes/dia)"
                                        value={this.invoice.due_date}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        type="text"
                                    />
                                }
                                <InputForm
                                    name="period_from"
                                    label="Perodio Desde (año/mes/dia)"
                                    value={this.invoice.period_from}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="period_to"
                                    label="Perodio Hasta (año/mes/dia)"
                                    value={this.invoice.period_to}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Actualizar Recibo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}