import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import { sessionService } from '../../services/SessionService';
import { drawSelects } from '../site/layout/common';

interface IndexContractProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexContract extends React.Component<IndexContractProps> {

    @observable private contracts: any;
    @observable private isLoaded = false;
    @observable private search: string = "";
    @observable private results: number = 0;
    @observable private total: number = 0;
    @observable private page: any = localStorage.getItem("contract_page") ? Number(localStorage.getItem("contract_page")) : 1;
    @observable private status: any = localStorage.getItem("contract_status") ? localStorage.getItem("contract_status") : "";
    @observable private sectorial: any = localStorage.getItem("contract_sectorial") ? localStorage.getItem("contract_sectorial") : "";
    @observable private cutoff: any = localStorage.getItem("contract_cutoff") ? localStorage.getItem("contract_cutoff") : "";
    @observable private sectoriales: any = [];
    @observable private cutoffs: any = [];
    @observable private sortBy: any = localStorage.getItem("contract_sort_by") ? localStorage.getItem("contract_sort_by") : "c.id";
    @observable private sortOrder: any = localStorage.getItem("contract_sort_order") ? localStorage.getItem("contract_sort_order") : "DESC";
    @observable private filter: any;
    @observable private monthSet: boolean = false;
    @observable private fromDate: Date = new Date();
    @observable private statusOptions: any = [
        { id: "Pendiente Instalacion", name: "Pendiente Instalacion" },
        { id: "Activo", name: "Activo" },
        { id: "Pendiente Desconexion", name: "Pendiente Desconexion" },
        { id: "Desconectado", name: "Desconectado" },
        { id: "Pendiente Conexion", name: "Pendiente Conexion" },
        { id: "Cerrado", name: "Cerrado" },
        { id: "Pendiente Retiro", name: "Pendiente Retiro" },
        { id: "Empleado", name: "Empleado" }
    ];


    onChange = (event: any) => {
        if (event.target.name === "status") {
            this.status = event.target.value;
            localStorage.setItem("contract_status", event.target.value);
        } else if (event.target.name === "sectorial") {
            this.sectorial = event.target.value;
            localStorage.setItem("contract_sectorial", event.target.value);
        } else if (event.target.name === "cutoff") {
            this.cutoff = event.target.value;
            localStorage.setItem("contract_cutoff", event.target.value);
        }
        this.page = 1;
        localStorage.setItem("contract_page", this.page);

        const obj = JSON.stringify({
            page: this.page,
            status: this.status,
            sectorial: this.sectorial,
            sort_by: this.sortBy,
            sort_order: this.sortOrder,
            cutoff: this.cutoff
        });
        http.post(`/api/contract/read`, obj).then((response) => {
            this.contracts = response.data.response.data;
            this.total = response.data.response.total;
        }).catch(() => {
            toast.error("Error cargando contratos");
        })
    }

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        this.filter = (match as any).params.filter;
        if (this.filter) {
            if (this.filter === "retiro") {
                this.status = "Pendiente Retiro";
                this.page = 1;
            } else if (this.filter === "desconexion") {
                this.status = "Pendiente Desconexion";
            } else {
                this.sortBy = "c.ip";
                this.sortOrder = "ASC"
                this.page = 1;
            }
        }
        http.post('/api/contract/read', JSON.stringify({ page: this.page, sort_by: this.sortBy, sort_order: this.sortOrder, status: this.status, sectorial: this.sectorial, cutoff: this.cutoff })).then((response) => {
            this.contracts = response.data.response.data;
            this.total = response.data.response.total;
        }).then(() => {
            return http.post('/api/sectorial/read', JSON.stringify({ sort_by: "name", sort_order: "ASC" })).then((response) => {
                this.sectoriales = response.data.response;
            }).then(() => {
                return http.get('/api/cutoff/read').then((response: any) => {
                    this.cutoffs = response.data.response;
                })
            }).then(() => {
                this.isLoaded = true;
            });
        }).catch((error) => {
            toast.error("Error cargando contratos");
        });
    }

    changePage = (page: any) => {
        this.page = page;
        localStorage.setItem("contract_page", page);
        const obj: any = { page: this.page, sort_by: this.sortBy, sort_order: this.sortOrder, status: this.status, sectorial: this.sectorial, cutoff: this.cutoff };
        if (this.monthSet) {
            obj.month = this.fromDate;
        }
        http.post(`/api/contract/read`, JSON.stringify(obj)).then((response) => {
            this.contracts = response.data.response.data;
            this.total = response.data.response.total;
            this.isLoaded = true;
        }).catch((error) => {
            toast.error("Error cargando contratos");
        });
    }
    buscarPorMes(): void {
        this.monthSet = true;
        this.changePage(this.page);
    }

    onSearchChange = (event: any) => {
        this.search = event.target.value;
    }

    searchContracts = () => {
        if (this.search.length === 0) {
            toast.error("Debes ingresar un valor");
            return;
        }
        const search = {
            keyword: this.search,
            filter: "all",
            sort_by: this.sortBy,
            sort_order: this.sortOrder
        }
        http.post('/api/contract/search', JSON.stringify(search)).then((response) => {
            if (response.data.response.length > 0) {
                this.results = response.data.response.length;
                this.contracts = response.data.response;
                this.total = response.data.total;
            } else {
                toast.error("No se encontraron contratos");
            }
        }).catch(() => {
            toast.error("Error cargando contratos");
        });
    }

    enterPressed = (event: any) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            this.searchContracts();
        }
    }

    setStartDate = (date: any, input: string) => {
        if (input === "from") {
            this.fromDate = date;
        }
    }

    changeSort = (value: string) => {
        this.sortBy = value;
        this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
        localStorage.setItem("contract_sort_by", this.sortBy);
        localStorage.setItem("contract_sort_order", this.sortOrder);
        this.changePage(this.page);
    }

    resetSearch = () => {
        this.onChange({
            target: {
                name: ""
            }
        });
        this.search = "";
        this.results = 0;
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                                        {sessionService.canViewSection("Contratos.cutoff") &&
                                            <Link to={`/contratos/cortes`}>
                                                <button className="btn btn-info btn-lg  pull-center">
                                                    <i className="material-icons">account_circle</i> Fechas de corte
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="text" name="search" autoComplete="off" placeholder="Buscar..." onChange={this.onSearchChange}
                                                className="form-control" value={this.search} onKeyPress={this.enterPressed} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de contratos</h4>
                                <p className="card-category">
                                    Lista de contratos dados de alta
                                    {
                                        this.results > 0 &&
                                        <span className="pull-right">
                                            Se encontraron {this.results} resultados
                                            <button onClick={this.resetSearch} style={{ height: 17, width: 9, padding: 10, marginLeft: 10 }} type="button" className="btn btn-danger">
                                                <i style={{ top: -9, right: 9 }} className="material-icons">close</i>
                                            </button>
                                        </span>
                                    }
                                </p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-10">
                                        <DatePicker selected={this.fromDate} onChange={date => this.setStartDate(date, "from")} dateFormat="MM/yyyy"
                                            showMonthYearPicker /><br />
                                        <button className="btn btn-warning btn-sm" onClick={() => this.buscarPorMes()}>
                                            <i className="material-icons">account_circle</i> Buscar contratos de este mes
                                            <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                    <div className="col-sm-2">
                                        {sessionService.canViewSection("Contratos.add") &&
                                            <Link to={`/contratos/nuevo`}>
                                                <button className="btn btn-info btn-lg  pull-right">
                                                    <i className="material-icons">account_circle</i> Nuevo Contrato
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                {
                                    this.total > 50 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={this.page}
                                            itemsCountPerPage={50}
                                            totalItemsCount={Number(this.total)}
                                            pageRangeDisplayed={5}
                                            onChange={this.changePage.bind(this)}
                                            activeClass="btn-warning"
                                            itemClass="btn btn-round btn-info"
                                            innerClass="btn-group paginador"
                                            disabledClass="deshabilitado"
                                        />
                                    </div>
                                }
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th>
                                                    {drawSelects(this.statusOptions, this.onChange, this.status, "Estatus", "status")}
                                                </th>
                                                <th>
                                                    {drawSelects(this.sectoriales, this.onChange, this.sectorial, "Sectorial", "sectorial")}
                                                </th>
                                                <th>
                                                    {drawSelects(this.cutoffs.map((x: any) => { return { id: x.date, name: x.date } }), this.onChange, this.cutoff, "Dia de Corte", "cutoff")}
                                                </th>
                                                <th></th>
                                                <th></th>
                                                <th>
                                                    <b>TOTAL: {this.total}</b>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th onClick={() => this.changeSort("c.id")} style={{ cursor: "pointer" }}>#</th>
                                                <th onClick={() => this.changeSort("client")} style={{ cursor: "pointer" }}>Cliente</th>
                                                <th onClick={() => this.changeSort("client_phone")} style={{ cursor: "pointer" }}>Telefono</th>
                                                <th onClick={() => this.changeSort("address")} style={{ cursor: "pointer" }}>Direccion</th>
                                                <th onClick={() => this.changeSort("c.status")} style={{ cursor: "pointer" }}>Status</th>
                                                <th onClick={() => this.changeSort("c.ip")} style={{ cursor: "pointer" }}>IP</th>
                                                <th onClick={() => this.changeSort("p.speed")} style={{ cursor: "pointer" }}>Megas</th>
                                                <th onClick={() => this.changeSort("sectorial")} style={{ cursor: "pointer" }}>Sectorial</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.contracts.map((contract: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{contract.id}</td>
                                                                <td>{contract.client}</td>
                                                                <td>{contract.client_phone}</td>
                                                                <td>{contract.address}</td>
                                                                <td>{contract.status}</td>
                                                                <td>{contract.ip}</td>
                                                                <td>{contract.speed}</td>
                                                                <td>{contract.sectorial}</td>
                                                                <td className="td-actions text-right">
                                                                    {sessionService.canViewSection("Contratos.view") &&
                                                                        <Link to={this.filter ? `../../contratos/ver/${contract.id}` : `./contratos/ver/${contract.id}`}>
                                                                            <button style={{ margin: 5 }} type="button" className="btn btn-info">
                                                                                <i className="material-icons">visibility</i>
                                                                            </button>
                                                                        </Link>
                                                                    }
                                                                    {sessionService.canViewSection("Contratos.edit") &&
                                                                        <Link to={this.filter ? `../../contratos/edit/${contract.id}` : `./contratos/edit/${contract.id}`}>
                                                                            <button style={{ margin: 5 }} type="button" className="btn btn-success">
                                                                                <i className="material-icons">edit</i>
                                                                            </button>
                                                                        </Link>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    this.total > 50 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={this.page}
                                            itemsCountPerPage={50}
                                            totalItemsCount={Number(this.total)}
                                            pageRangeDisplayed={5}
                                            onChange={this.changePage.bind(this)}
                                            activeClass="btn-warning"
                                            itemClass="btn btn-round btn-info"
                                            innerClass="btn-group paginador"
                                            disabledClass="deshabilitado"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}