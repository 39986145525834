import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { observable } from 'mobx';
import Pagination from "react-js-pagination";
import { sessionService } from '../../services/SessionService';

interface IndexClientProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexClient extends React.Component<IndexClientProps> {

    @observable private clients: any;
    @observable private isLoaded = false;
    @observable private search: string = "";
    @observable private results: number = 0;
    @observable private total: number = 0;
    @observable private page: any = 1;
    @observable private sortBy: any = "c.id";
    @observable private sortOrder: any = "DESC";

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.post('/api/client/read', JSON.stringify({ page: this.page, sort_by: this.sortBy, sort_order: this.sortOrder })).then((response) => {
            this.clients = response.data.response.data;
            this.total = response.data.response.total;
            this.isLoaded = true;
        }).catch((error) => {
            toast.error("Error cargando clientes");
        });
    }

    onSearchChange = (event: any) => {
        this.search = event.target.value;
    }

    changePage = (page: any) => {
        this.page = page;
        http.post(`/api/client/read`, JSON.stringify({ page: this.page, sort_by: this.sortBy, sort_order: this.sortOrder })).then((response) => {
            this.clients = response.data.response.data;
            this.isLoaded = true;
        }).catch((error) => {
            toast.error("Error cargando contratos");
        });
    }

    searchClients = () => {
        if (this.search.length === 0) {
            toast.error("Debes ingresar un valor");
            return;
        }
        const search = {
            keyword: this.search
        }
        http.post('/api/client/search', JSON.stringify(search)).then((response) => {
            if (response.data.response.length > 0) {
                this.results = response.data.response.length;
                this.clients = response.data.response;
            } else {
                toast.error("No se encontraron clientes");
            }
        }).catch(() => {
            toast.error("Error cargando clientes");
        });
    }

    enterPressed = (event: any) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            this.searchClients();
        }
    }

    resetSearch = () => {
        http.get('/api/client/read').then((response) => {
            this.clients = response.data.response;
            this.search = "";
            this.results = 0;
        }).catch(() => {
            toast.error("Error cargando clientes");
        });
    }

    changeSort = (value: string) => {
        this.sortBy = value;
        this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
        this.changePage(this.page);
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="text" name="search" autoComplete="off" placeholder="Buscar..." onChange={this.onSearchChange}
                                                className="form-control" value={this.search} onKeyPress={this.enterPressed} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de clientes</h4>
                                <p className="card-category">
                                    Lista de clientes dados de alta
                                    {
                                        this.results > 0 &&
                                        <span className="pull-right">
                                            Se encontraron {this.results} resultados
                                            <button onClick={this.resetSearch} style={{ height: 17, width: 9, padding: 10, marginLeft: 10 }} type="button" className="btn btn-danger">
                                                <i style={{ top: -9, right: 9 }} className="material-icons">close</i>
                                            </button>
                                        </span>
                                    }
                                </p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        {sessionService.canViewSection("Clientes.add") &&
                                            <Link to={`/clientes/nuevo`}>
                                                <button className="btn btn-info btn-lg  pull-right">
                                                    <i className="material-icons">account_circle</i> Nuevo Cliente
                                                <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                {
                                    this.total > 50 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={this.page}
                                            itemsCountPerPage={50}
                                            totalItemsCount={Number(this.total)}
                                            pageRangeDisplayed={5}
                                            onChange={this.changePage.bind(this)}
                                            activeClass="btn-warning"
                                            itemClass="btn btn-round btn-info"
                                            innerClass="btn-group paginador"
                                            disabledClass="deshabilitado"
                                        />
                                    </div>
                                }
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th onClick={() => this.changeSort("c.id")} style={{ cursor: "pointer" }}>Id</th>
                                                <th onClick={() => this.changeSort("c.name")} style={{ cursor: "pointer" }}>Nombre</th>
                                                <th onClick={() => this.changeSort("fulladdress")} style={{ cursor: "pointer" }}>Dirección</th>
                                                <th onClick={() => this.changeSort("c.phone")} style={{ cursor: "pointer" }}>Teléfono</th>
                                                <th onClick={() => this.changeSort("contracts")} style={{ cursor: "pointer" }}>Contratos</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.clients.map((client: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{client.id}</td>
                                                                <td>{client.name}</td>
                                                                <td>{client.full_address}</td>
                                                                <td>{client.phone}</td>
                                                                <td>{client.contracts}</td>
                                                                <td className="td-actions text-right">
                                                                    {sessionService.canViewSection("Clientes.view") &&
                                                                        <Link to={`clientes/ver/${client.id}`}>
                                                                            <button style={{ margin: 5 }} type="button" className="btn btn-info">
                                                                                <i className="material-icons">visibility</i>
                                                                            </button>
                                                                        </Link>
                                                                    }   {sessionService.canViewSection("Clientes.edit") &&
                                                                        <Link to={`clientes/${client.id}`}>
                                                                            <button style={{ margin: 5 }} type="button" className="btn btn-success">
                                                                                <i className="material-icons">edit</i>
                                                                            </button>
                                                                        </Link>
                                                                    }   {sessionService.canViewSection("Clientes.delete") &&
                                                                        <button onClick={() => this.deleteClient(client.id, index)} style={{ margin: 5 }} type="button" className="btn btn-danger" >
                                                                            <i className="material-icons">close</i>
                                                                        </button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    private deleteClient = (id: any, index: any): void => {
        swal({
            title: "Confirmar acción",
            text: "¿Estas seguro que quieres borrar el cliente?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    const deleteObject = JSON.stringify({
                        id
                    });
                    http.post(`/api/client/delete`, deleteObject)
                        .then((response) => {
                            this.clients.splice(index, 1);
                            toast.success("Cliente borrado correctamente");
                        }).catch((error) => {
                            if (error.includes("foreign")) {
                                toast.error("Error al borrar: Registros ligados a este cliente");
                            } else {
                                toast.error("Error borrando cliente");
                            }
                        })
                }
            });
    }
}