import React, { useState, Fragment } from 'react';
import { observable } from "mobx"
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Index from '../../index/Index';
import Login from './Login';
import { sessionService } from '../../../services/SessionService';
import User from '../../users/User';
import Role from '../../roles/Role';
import Package from '../../packages/Package';
import { createBrowserHistory } from "history";
import Client from '../../clients/Client';
import Contract from '../../contracts/Contract';
import Sectorial from '../../sectorial/Sectorial';
import Invoice from '../../invoices/Invoice';
import Installation from '../../installations/Installation';
import Connection from '../../connections/Connection';
import Support from '../../support/Support';
import Payment from '../../payments/Payment';
import { MENU } from '../menu';
import SupportVisits from '../../support_visits/SupportVisits';
import Antenna from '../../antennas/Antenna';
import Inventory from '../../inventory/Inventory';
import Report from '../../report/Report';
import packageJson from '../../../../package.json';

interface LayoutProps {
  toggled: boolean;
  changeToggle(action?: boolean): void
}
const history = createBrowserHistory({ forceRefresh: true });

@observer export default class Layout extends React.Component<LayoutProps> {

  @observable private selectedMenu = "Inicio";

  private sidebarRef = React.createRef<HTMLInputElement>();


  componentDidMount() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('mousedown', this.handleClickOutside);
    this.init();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  init = () => {
    const currentLocation = "/" + window.location.pathname.split("/")[1];
    const currentSection = MENU.find((section: any) => section.url === currentLocation);
    this.selectedMenu = currentSection ? currentSection.name : "";
    this.props.changeToggle(true);
  }

  handleClickOutside(event: any) {
    if (this.sidebarRef && this.sidebarRef.current && !this.sidebarRef.current.contains(event.target)) {
      this.props.changeToggle(true);
    }
  }

  private selectMenu = (value: any) => {
    this.selectedMenu = value.name;
  }

  private logOut = (event: any) => {
    event.preventDefault();
    swal({
      title: "Cerrar Sesión",
      text: "¿Estas seguro que quieres cerrar sesión?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          localStorage.removeItem("token");
          sessionService.logOut();
          toast.success("Cerraste tu sesión");
          history.push("/");
        }
      });
  }

  render() {
    const isLogged = sessionService.isLogged();
    const pageWidth = {
      width: isLogged ? '' : '100%'
    }
    return (
      <Fragment>
        <Router>
          {isLogged &&
            <div className="sidebar" data-color="purple" data-background-color="white" ref={this.sidebarRef}>
              <div className="logo">
                <span className="simple-text logo-normal" style={{ backgroundColor: "#fff" }}>
                  {process.env.NODE_ENV === "development" ? "SERVICIOS INALÁMBRICOS" : process.env.REACT_APP_TITLE} <br />
                  {packageJson.version}
                </span>
              </div>
              <div className="logo" style={{ padding: 0 }}>
                <span className="simple-text" style={{ backgroundColor: "#fff", textTransform: "none", fontSize: 15 }}>
                  {sessionService.getUserInfo() && sessionService.getUserInfo().name}
                </span>
              </div>
              <div className="sidebar-wrapper" style={{ backgroundColor: "#fff" }}>
                <ul className="nav">
                  {
                    MENU.map((value: any, index: any) => {
                      const canView = (index === 0) || (sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf(value.name) >= 0);
                      if (!canView) {
                        return null;
                      }
                      return (
                        <Fragment key={index}>
                          <li className={this.selectedMenu === value.name ? "nav-item active" : "nav-item"}>
                            <Link to={value.url} className="nav-link" onClick={() => this.selectMenu(value)}>
                              <i className="material-icons">{value.icon}</i>
                              <p>{value.menuName}</p>
                            </Link>
                          </li>
                        </Fragment>

                      )
                    })
                  }
                  <li className="nav-item active">
                    <Link to="/" className="nav-link" style={{ backgroundColor: "#b13838" }} onClick={this.logOut}>
                      <i className="material-icons">login</i>
                      <p>Cerrar sesión</p>
                    </Link>
                  </li>
                </ul>

              </div>
            </div>
          }

          <div className="main-panel" style={pageWidth}>
            {isLogged &&
              <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
                <div className="container-fluid">
                  <div className="navbar-wrapper">
                    <span className="navbar-brand">{this.selectedMenu}</span>
                  </div>
                  <button onClick={() => this.props.changeToggle()} className={this.props.toggled ? "navbar-toggler toggled" : "navbar-toggler"} type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                    <span className="navbar-toggler-icon icon-bar"></span>
                  </button>
                </div>
              </nav>
            }
            <div className="content">
              <div className="container-fluid">
                {isLogged &&
                  <Switch>
                    <Route path="/clientes"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Client {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/contratos"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Contract {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/recibos"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Invoice {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/paquetes"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Package {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/sectoriales"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Sectorial {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/instalaciones"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Installation {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/soporte"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Support {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/visitas"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <SupportVisits {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/conexiones"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Connection {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/pagos"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Payment {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/antenas"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Antenna {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/inventario"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Inventory {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/roles"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Role {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/reporte"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Report {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/usuarios"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <User {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                    <Route path="/"
                      render={(props) => (
                        <Reloadable location={props.location}>
                          <Index {...props} changeToggle={this.props.changeToggle} />
                        </Reloadable>
                      )}>
                    </Route>
                  </Switch>
                }

                {!isLogged && sessionService.showLogin() &&
                  <Login />
                }
              </div>
            </div>
            {this.props.toggled &&
              <div className="close-layer visible"></div>
            }
          </div>
        </Router>
      </Fragment>
    )
  }
}

const Reloadable = (props: any) => {
  const { location } = props
  const [key, setKey] = useState(location.key)

  if (location.key !== key) {
    setTimeout(() => setKey(location.key), 0)
    return null
  }
  return <>{props.children}</>
}