import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexUser from './IndexUser';
import EditUser from './EditUser';
import NewUser from './NewUser';

interface UserProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class User extends React.Component<UserProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/usuarios"
            render={(props) => (
              <IndexUser {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/usuarios/nuevo"
            render={(props) => (
              <NewUser {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/usuarios/:id"
            render={(props) => (
              <EditUser {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}