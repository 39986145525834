import React from 'react';

function drawSelects(values: any, ...props: any) {
    const [onChange, value, title, name] = props;
    return (
        <div className="form-group bmd-form-group">
            <select name={name} onChange={onChange} value={value} className="form-control custom-select">
                <option value="">--{title}</option>
                {values && values.map((option: any, index: any) => {
                    return (
                        <option value={option.id} key={index}>{option.name}</option>
                    )
                })}
            </select>
        </div>
    )
}

export { drawSelects };