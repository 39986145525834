import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexInventory from './IndexInventory';
import NewInventory from './NewInventory';
import EditInventory from './EditInventory';

interface InventoryProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Inventory extends React.Component<InventoryProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/inventario"
            render={(props) => (
              <IndexInventory {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/inventario/nuevo"
            render={(props) => (
              <NewInventory {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/inventario/:id"
            render={(props) => (
              <EditInventory {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}