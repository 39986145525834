import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../../site/layout/InputForm';
import { sessionService } from '../../../services/SessionService';

interface NewCardProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewCard extends React.Component<NewCardProps> {

    @observable private card: any = {
        name: ""
    };
    @observable private redirect = false;
    @observable private validationErrors: string[] = [];

    componentDidMount() {
        this.props.changeToggle(true);
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.card[name] = value;
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        this.card.created_by = sessionService.getUserInfo().id;

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/card/create`, JSON.stringify(this.card))
            .then(() => {
                toast.success(`Tarjeta agregada`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (this.redirect) {
            return <Redirect to="/pagos/tarjetas" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Tarjeta: {this.card.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../pagos/tarjetas`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="name"
                                    label="Tarjeta"
                                    value={this.card.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Agregar Tarjeta</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}