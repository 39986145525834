import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';
import { apiUrl } from '../../services/HttpInterceptorUtil';

interface EditSupportProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditSupport extends React.Component<EditSupportProps> {

    @observable private support: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private users: any = [];
    @observable private validationErrors: string[] = [];
    @observable private hasCost = false;
    @observable private photos: any;
    @observable private status: any = [
        {
            id: "Pendiente Visita",
            name: "Pendiente Visita"
        },
        {
            id: "Resuelto",
            name: "Resuelto"
        }
    ];

    @observable private startingStatus: any = "";

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/support/read/${id}`).then((response) => {
            this.support = response.data;
            this.support.service_cost = "";
            this.support.response = this.support.response ? this.support.response : "";
            this.support.visited_by = this.support.visited_by ? this.support.visited_by : "";
            this.startingStatus = this.support.status;
        }).then(() => {
            const obj = JSON.stringify({
                claim: "Visitas de soporte"
            })
            http.post(`/api/user/read`, obj).then((response) => {
                this.users = response.data.response;
                this.isLoaded = true;
            });
        })
            .catch((error) => {
                toast.error("Error cargando orden de soporte");
            });
    }

    loadPhotos = () => {
        http.get(`/api/support/images/${this.support.id}`).then((response) => {
            this.photos = response.data;
        });
    }

    private handleChange = (event: any) => {
        if (event.target.name === "response") {
            this.support.response = event.target.value;
        } else if (event.target.name === "status") {
            this.support.status = event.target.value;
        } else if (event.target.name === "visit_comments") {
            this.support.visit_comments = event.target.value;
        } else if (event.target.name === "visited_by") {
            this.support.visited_by = event.target.value;
        } else if (event.target.name === "service_cost") {
            this.support.service_cost = event.target.value;
        } else if (event.target.name === "hasCost") {
            this.hasCost = !this.hasCost;
            if (!this.hasCost) {
                this.support.service_cost = "";
            }
        }
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if (this.startingStatus === "Nuevo") {
            this.support.resolved_by = sessionService.getUserInfo().id;
        }

        if (this.support.visited_by === "") {
            delete this.support.visited_by;
        }

        if (this.support.service_cost !== "") {
            this.support.service_cost = this.support.service_cost.replace("$", '').replace(/,/g, '');
        } else {
            delete this.support.service_cost;
        }

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/support/update`, JSON.stringify(this.support))
            .then(() => {
                toast.success(`Orden de soporte actualizada`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    onPrint = () => {
        window.open(`${apiUrl().url.API_URL}/api/support/print/${this.support.id}`, "_blank");
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/soporte" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Orden de soporte para el contracto #{this.support.contract_id} - {this.support.ip} - {this.support.sectorial} - {this.support.address}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"><h2>Status: {this.startingStatus}</h2></div>
                                    <div className="col-sm-2">
                                        <Link to={`../soporte`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">                                
                                    <div className="col-sm-12">
                                        <button className="btn btn-primary btn-lg" onClick={() => this.onPrint()} >
                                            <i className="material-icons">print</i>
                                            <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                </div>
                                <InputForm
                                    name="content"
                                    label="Descripción del problema"
                                    value={this.support.content}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    disabled={true}
                                />
                                {this.startingStatus !== "Pendiente Visita" && this.startingStatus !== "Resuelto" &&
                                    <InputForm
                                        name="status"
                                        label="Cambiar el estatus de la orden a"
                                        value={this.support.status}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        options={this.status}
                                        type="select"
                                    />
                                } {this.startingStatus === "Pendiente Visita" &&
                                    <InputForm
                                        name="visited_by"
                                        label="Asignar la visita a"
                                        value={this.support.visited_by}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        options={this.users}
                                        disabled={this.startingStatus === "Resuelto"}
                                        type="select"
                                    />
                                }
                                {this.startingStatus === "Pendiente Visita" &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>El soporte tendra costo?</label>
                                                <div className="form-group bmd-form-group">
                                                    <input name="hasCost" type="checkbox" onChange={this.handleChange} checked={this.hasCost}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {this.hasCost &&
                                    <InputForm
                                        name="service_cost"
                                        label="Costo del servicio"
                                        value={this.support.service_cost}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        type="number"
                                        prefix="$"
                                        decimal={2}
                                    />
                                }

                                <InputForm
                                    name="response"
                                    label="Resolución"
                                    value={this.support.response}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    disabled={this.startingStatus === "Resuelto"}
                                />
                                {this.startingStatus === "Resuelto" && this.support.visit_comments !== "" &&
                                    <InputForm
                                        name="visit_comments"
                                        label="Notas de la visita"
                                        value={this.support.visit_comments}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        type="textarea"
                                        disabled={true}
                                    />
                                }
                                <div className="col-sm-12">
                                    {!this.photos && this.startingStatus === "Resuelto" &&
                                        <button onClick={this.loadPhotos} className="btn btn-warning btn-lg">
                                            Cargar imagenes
                                        <div className="ripple-container"></div>
                                        </button>
                                    }
                                    {this.photos && this.photos.length === 0 &&
                                        <h2>No hay imagenes</h2>
                                    }
                                    {this.photos && this.photos.map((photo: any, index: any) => {
                                        return (
                                            <Fragment key={index}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <img src={apiUrl().url.API_URL + photo} alt="Imagen" />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    })}
                                </div>
                                <button disabled={this.startingStatus === "Resuelto"} onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Resolver la Orden de Soporte</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}