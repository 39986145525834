import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { observable } from 'mobx';
import { sessionService } from '../../services/SessionService';

interface IndexPackageProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexPackage extends React.Component<IndexPackageProps> {

    @observable private packages: any;
    @observable private isLoaded = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.get('/api/package/read').then((response) => {
            this.packages = response.data.response;
            this.isLoaded = true;
        }).catch(() => {
            toast.error("Error cargando paquetes");
        });
    }

    render() {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de paquetes</h4>
                                <p className="card-category">Lista de paquetes disponibles</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        {sessionService.canViewSection("Paquetes.add") &&
                                            <Link to={`/paquetes/nuevo`}>
                                                <button className="btn btn-info btn-lg  pull-right">
                                                    <i className="material-icons">account_circle</i> Nuevo Paquete
                                                <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Velocidad</th>
                                                <th>Costo</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.packages.map((pack: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{pack.name}</td>
                                                                <td>{pack.speed} mb</td>
                                                                <td>{formatter.format(pack.cost)}</td>
                                                                <td className="td-actions text-right">
                                                                    {sessionService.canViewSection("Paquetes.edit") &&
                                                                        <Link to={`paquetes/${pack.id}`}>
                                                                            <button style={{ margin: 5 }} type="button" className="btn btn-success">
                                                                                <i className="material-icons">edit</i>
                                                                            </button>
                                                                        </Link>
                                                                    }
                                                                    {sessionService.canViewSection("Paquetes.delete") &&
                                                                        <button onClick={() => this.deletePackage(pack.id, index)} style={{ margin: 5 }} type="button" className="btn btn-danger" >
                                                                            <i className="material-icons">close</i>
                                                                        </button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    private deletePackage = (id: any, index: any): void => {
        swal({
            title: "Confirmar acción",
            text: "¿Estas seguro que quieres borrar el paquete?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    const deleteObject = JSON.stringify({
                        id
                    });
                    http.post(`/api/package/delete`, deleteObject)
                        .then((response) => {
                            this.packages.splice(index, 1);
                            toast.success("Paquete borrado correctamente");
                        }).catch((error) => {
                            if (error.includes("foreign")) {
                                toast.error("Error al borrar: Registros ligados a este paquete");
                            } else {
                                toast.error("Error borrando paquete");
                            }
                        })
                }
            });
    }
}