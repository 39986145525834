import { observable } from "mobx";

class LoadService {
    @observable private _activeCalls: number;
    constructor() {
        this._activeCalls = 0;
    }

    public isRunning(): boolean {
        return (this._activeCalls > 0);
    }

    public removeCall(): void {
        if (this._activeCalls > 0) {
            this._activeCalls--;
        }
    }

    public addCall(): void {
        this._activeCalls++;
    }

}
export const loadService = new LoadService();