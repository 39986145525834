import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { createBrowserHistory } from "history";
import Pagination from "react-js-pagination";

const history = createBrowserHistory({ forceRefresh: true });

interface IndexSupportProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexSupport extends React.Component<IndexSupportProps> {

    @observable private support_tickets: any;
    @observable private isLoaded = false;
    @observable private status: any = localStorage.getItem("support_status") ? localStorage.getItem("support_status") : "";
    @observable private statuses = [
        "Nuevo",
        "Pendiente Visita",
        "Resuelto"
    ];
    @observable private page: any = 1;
    @observable private sortBy: any = "s.id";
    @observable private sortOrder: any = "DESC";
    @observable private total: number = 0;
    
    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const obj = JSON.stringify({
            status: this.status,
            page: this.page, 
            sort_by: this.sortBy, 
            sort_order: this.sortOrder 
        });
        http.post('/api/support/read', obj).then((response) => {
            this.support_tickets = response.data.response.data;
            this.total = response.data.response.total;
            this.isLoaded = true;
        }).catch((error) => {
            toast.error("Error cargando tickets de soporte");
        });
    }

    changePage = (page: any) => {
            this.page = page;
            const obj = JSON.stringify({
                status: this.status,
                page: this.page, 
                sort_by: this.sortBy, 
                sort_order: this.sortOrder 
            });
            http.post('/api/support/read', obj).then((response) => {
                this.support_tickets = response.data.response.data;
                this.isLoaded = true;
            }).catch((error) => {
                toast.error("Error cargando tickets de soporte");
            });
        }

    onChange = (event: any) => {
        if (event.target.name === "status") {
            this.status = event.target.value;
            localStorage.setItem("support_status", event.target.value);
        }
        const obj = JSON.stringify({
            status: this.status,
            page: 1, 
            sort_by: this.sortBy, 
            sort_order: this.sortOrder 
        });
        http.post('/api/support/read', obj).then((response) => {
            this.support_tickets = response.data.response.data;
            this.total = response.data.response.total;
            this.isLoaded = true;
        }).catch((error) => {
            toast.error("Error cargando tickets de soporte");
        });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de tickets de soporte</h4>
                                <p className="card-category">Lista de tickets de soporte generados</p>
                            </div>
                            <div className="card-body">
                            {
                                    this.total > 50 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={this.page}
                                            itemsCountPerPage={50}
                                            totalItemsCount={Number(this.total)}
                                            pageRangeDisplayed={5}
                                            onChange={this.changePage.bind(this)}
                                            activeClass="btn-warning"
                                            itemClass="btn btn-round btn-info"
                                            innerClass="btn-group paginador"
                                            disabledClass="deshabilitado"
                                        />
                                    </div>
                                }
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th className="text-right"><div className="form-group bmd-form-group">
                                                    <select name="status" onChange={this.onChange} value={this.status} className="form-control custom-select">
                                                        <option value="">--Estatus</option>
                                                        {this.statuses && this.statuses.map((option: any, index: any) => {
                                                            return (
                                                                <option value={option} key={index}>{option}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div></th>
                                            </tr>
                                            <tr>
                                                <th>ID</th>
                                                <th># Contrato</th>
                                                <th>Status</th>
                                                <th>Visita asignado</th>
                                                <th>Creado</th>
                                                <th>Resuelto</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.support_tickets.map((support: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{support.id}</td>
                                                                <td  onClick={() => support.contract_id ? history.push(`/contratos/ver/${support.contract_id}`) : ''} style={{cursor:"pointer"}}>{support.contract_id}</td>
                                                                <td>{support.status}</td>
                                                                <td>{support.visited_by ? support.name : "No"}</td>
                                                                <td>{support.created_date}</td>
                                                                <td>{support.resolved_date}</td>
                                                                <td className="td-actions text-right">
                                                                    <Link to={`soporte/${support.id}`}>
                                                                        <button style={{ margin: 5 }} type="button" className="btn btn-info">
                                                                            <i className="material-icons">visibility</i>
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    this.total > 50 &&
                                    <div className="row">
                                        <Pagination
                                            activePage={this.page}
                                            itemsCountPerPage={50}
                                            totalItemsCount={Number(this.total)}
                                            pageRangeDisplayed={5}
                                            onChange={this.changePage.bind(this)}
                                            activeClass="btn-warning"
                                            itemClass="btn btn-round btn-info"
                                            innerClass="btn-group paginador"
                                            disabledClass="deshabilitado"
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}