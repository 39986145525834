import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import swal from 'sweetalert';
import { sessionService } from '../../services/SessionService';

interface EditInstallationProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditInstallation extends React.Component<EditInstallationProps> {

    @observable private contract: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [
        "ip",
        "sectorial_id"
    ];
    @observable private sectorials: any = [];
    @observable private usedIps: any = [];
    @observable private availableIps: any = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/contract/read/${id}`).then((response) => {
            this.contract = response.data;
            this.contract.sectorial_id = "";
            this.contract.ip = "";
        })
            .then(() => {
                http.get(`/api/sectorial/available`).then((response) => {
                    this.sectorials = response.data.response;
                    this.isLoaded = true;
                });
            })
            .catch(() => {
                toast.error("Error cargando contrato");
            });
    }

    private handleSectorialChange = () => {
        if (this.contract.sectorial_id === "") {
            this.availableIps = [];
            this.contract.ip = "";
            return;
        }
        const request = JSON.stringify({
            id: this.contract.sectorial_id
        });
        http.post(`/api/sectorial/ips`, request).then((response) => {
            this.usedIps = response.data.response;
            this.generateAvailableIps();
        })
    }

    private generateAvailableIps = () => {
        const sectorial = this.sectorials.find((x: any) => {
            return x.id === this.contract.sectorial_id
        });
        this.availableIps = [];
        for (let i = Number(sectorial.range_start); i <= Number(sectorial.range_end); i++) {
            if (this.usedIps.indexOf(`${sectorial.ip_start}.${i}`) === -1) {
                this.availableIps.push({
                    name: `${sectorial.ip_start}.${i}`,
                    value: `${sectorial.ip_start}.${i}`
                });
            }
        }
        this.contract.ip = this.availableIps[0].value;
        this.setValidation(false, "ip");
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.contract[name] = value;
        if(name === "sectorial_id") {
            this.handleSectorialChange();
        }
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        // eslint-disable-next-line
        this.contract.reference_phone = this.contract.reference_phone.replace(/\-/g, '');
        this.contract.installed_by = sessionService.getUserInfo().id;

        swal({
            title: "Confirmar acción",
            text: "¿Quieres guardar tus cambios?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willSave => {
                if (willSave) {
                    http.post(`/api/contract/install`, JSON.stringify(this.contract))
                        .then(() => {
                            toast.success(`Instalación guardada`);
                            this.redirect = true;
                        }).catch((error) => {
                            if (Array.isArray(error)) {
                                error.forEach((val) => {
                                    toast.error(val);
                                })
                            } else {
                                toast.error(error);
                            }
                        });
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }

        if (this.redirect) {
            return <Redirect to="/instalaciones" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Instalar Contrato #{this.contract.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../contratos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Cliente</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.client_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Teléfono de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_phone}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Relación de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_relationship}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Dirección</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_street}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Colonia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_colonia}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Fecha de corte</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.cutoff_date}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Paquete contratado</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.package_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Forma de cobro</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.payment_type}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Datos de Instalación</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <label className="col-sm-4">IP</label>
                                    <div className="col-sm-8">
                                        <h3>{this.contract.ip}</h3>
                                    </div>
                                </div>
                                <InputForm
                                    name="sectorial_id"
                                    label="Sectorial"
                                    value={this.contract.sectorial_id}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    options={this.sectorials}
                                    type="select"
                                />
                                <InputForm
                                    name="ip"
                                    label="Elegir otra IP"
                                    value={this.contract.ip}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    options={this.availableIps}
                                    type="select"
                                />
                                <InputForm
                                    name="notes_installation"
                                    label="Notas (Max: 255 caracteres)"
                                    value={this.contract.notes_installation}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    maxLength={255}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Guardar datos de instalación</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}