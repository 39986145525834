import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import firebase from '../../firebase';
import { sessionService } from '../../services/SessionService';
import { http } from '../../services/HttpInterceptor';
import { toast } from 'react-toastify';
import { observable } from 'mobx';

interface IndexProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class Index extends React.Component<IndexProps> {

    @observable private totales: any = [];
    componentDidMount() {
        this.init();
    }

    init = () => {
        if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();
            Notification.requestPermission().then(() => {
                return messaging.getToken();
            }).then((token:any) => {
                const obj = JSON.stringify({
                    id: sessionService.getUserInfo().id,
                    token
                });
                if (!sessionService.isTokenUpdated()) {
                    http.post(`/api/user/updateToken`, obj).then((response) => {
                        sessionService.setTokenUpdated();
                    });
                }
            }).catch((error:any) => {
                toast.error("Error al inicializar notificaciones");
            });
        } else {
            toast.error("El navegador no soporta notificaciones");
        }

        http.get("/api/dashboard/read").then((response) => {
            this.totales = response.data;
        }).catch((error) => {
            toast.error("Error cargando estadisticas");
        });
    }

    render() {
        return (
            <Fragment>
                <div>
                    <div className="row">
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Clientes") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-info card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">people</i>
                                        </div>
                                        <p className="card-category">Clientes</p>
                                        <h3 className="card-title">{this.totales.total_clients}
                                            <small> clientes</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="clientes/nuevo">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">people</i>
                                                Agregar un nuevo cliente
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Contratos") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-info card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">analytics</i>
                                        </div>
                                        <p className="card-category">Contratos</p>
                                        <h3 className="card-title"> {this.totales.total_contracts}
                                            <small> contratos</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="contratos/nuevo">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">analytics</i>
                                                Agregar un nuevo contrato
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Contratos") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-danger card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">analytics</i>
                                        </div>
                                        <p className="card-category">Contratos sin IP</p>
                                        <h3 className="card-title"> {this.totales.total_contracts_no_ip}
                                            <small> contratos</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="contratos/filtro/ip">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">analytics</i>
                                                Ver Contratos
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Contratos") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-danger card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">analytics</i>
                                        </div>
                                        <p className="card-category">Contratos pendientes desconexion</p>
                                        <h3 className="card-title"> {this.totales.total_desconexion}
                                            <small> contratos</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="contratos/filtro/desconexion">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">analytics</i>
                                                Ver Contratos
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Contratos") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-danger card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">analytics</i>
                                        </div>
                                        <p className="card-category">Contratos Pendientes Retiro</p>
                                        <h3 className="card-title"> {this.totales.total_retiros}
                                            <small> contratos</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="contratos/filtro/retiro">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">analytics</i>
                                                Ver Contratos
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Pagos") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-info card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">payments</i>
                                        </div>
                                        <p className="card-category">Pagos del mes</p>
                                        <h3 className="card-title">{this.totales.total_payments}
                                            <small> pagos</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="pagos">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">payments</i>
                                                Ver pagos
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Recibos") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-info card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">receipt</i>
                                        </div>
                                        <p className="card-category">Recibos del mes</p>
                                        <h3 className="card-title">{this.totales.total_invoices}
                                            <small> recibos</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="recibos">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">receipt</i>
                                                Ver recibos
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Recibos") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-danger card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">error</i>
                                        </div>
                                        <p className="card-category">Recibos vencidos</p>
                                        <h3 className="card-title">{this.totales.total_invoices_expired}
                                            <small> recibos</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="recibos">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">error</i>
                                                Ver recibos
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Sectoriales") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-info card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">library_books</i>
                                        </div>
                                        <p className="card-category">Sectoriales</p>
                                        <h3 className="card-title">{this.totales.total_sectorial}
                                            <small> sectoriales</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="sectoriales">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">library_books</i>
                                                Ver sectoriales
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Instalaciones") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-warning card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">construction</i>
                                        </div>
                                        <p className="card-category">Instalaciones pendientes</p>
                                        <h3 className="card-title">{this.totales.total_installations}
                                            <small> instalaciones</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="instalaciones">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">construction</i>
                                                Ver instalaciones
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Conexiones") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-warning card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">router</i>
                                        </div>
                                        <p className="card-category">Conexiones pendientes</p>
                                        <h3 className="card-title">{this.totales.total_connections}
                                            <small> conexiones</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="conexiones">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">router</i>
                                                Ver conexiones
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Ordenes de Soporte") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-warning card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">help</i>
                                        </div>
                                        <p className="card-category">Ordenes de soporte</p>
                                        <h3 className="card-title">{this.totales.total_support_order}
                                            <small> ordenes de soporte</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="soporte">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">help</i>
                                                Ver ordenes de soporte
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        {(sessionService.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf("Paquetes") >= 0) &&
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="card card-stats">
                                    <div className="card-header card-header-info card-header-icon">
                                        <div className="card-icon">
                                            <i className="material-icons">folder</i>
                                        </div>
                                        <p className="card-category">Paquetes</p>
                                        <h3 className="card-title">{this.totales.total_packages}
                                            <small> paquetes</small>
                                        </h3>
                                    </div>
                                    <div className="card-footer">
                                        <Link to="paquetes">
                                            <div className="stats" style={{ cursor: "pointer" }}>
                                                <i className="material-icons">folder</i>
                                                Ver paquetes
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}