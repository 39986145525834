import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import NumberFormat from 'react-number-format';
import { Dropdown } from 'semantic-ui-react'

interface InputFormProps {
    label: string;
    name: string;
    value: string;
    required: boolean;
    type: string;
    disabled?: boolean;
    minLength?: number;
    setValidation?(value: boolean, name: string): void;
    onChange?(event: any): void;
    prefix?: string;
    decimal?: number;
    suffix?: string;
    thousandSeparator?: boolean;
    format?: string | FormatInputValueFunction;
    options?: any[];
    customValidation?(event: any): void;
    maxLength?: number;
}

export type FormatInputValueFunction = (inputValue: string) => string;

@observer export default class InputForm extends React.Component<InputFormProps> {

    @observable private validationError: string = ""
    private strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})");

    componentDidMount() {
        if (!this.props.customValidation) {
            this.validationError = (this.props.required && this.props.value.length === 0) ? "Este campo no puede estar vacío." : "";
        }
    }

    private onChange = (event: any, object?: any): void => {
        if (object) {
            event = {
                target: {
                    name: this.props.name,
                    value: object.value
                }
            }
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }

        if (this.props.customValidation) {
            this.props.customValidation(event)
            return;
        }

        if (this.props.required && event.target.value === "") {
            if (this.props.setValidation) {
                this.props.setValidation(true, this.props.name);
            }
            this.validationError = "Este campo no puede estar vacío.";
        } else {
            this.validationError = "";
            if (this.props.setValidation) {
                this.props.setValidation(false, this.props.name);
            }
        }

        if (this.props.minLength && event.target.value !== "" && event.target.value.length < this.props.minLength) {
            if (this.props.setValidation) {
                this.props.setValidation(true, this.props.name);
            }
            this.validationError = `Este campo requiere al menos ${this.props.minLength} caracteres.`;
        }

        if (this.props.type === "password" && event.target.value.length > 0) {
            if (this.strongRegex.test(this.props.value) && this.props.setValidation) {
                this.props.setValidation(false, this.props.name);
            } else {
                this.validationError = `Debes elegir un password mas fuerte. (1 Mayuscula, 1 Minuscula, 1 numero, 1 caracter especial, 8 caracteres)`;
                if (this.props.setValidation) {
                    this.props.setValidation(true, this.props.name);
                }
            }
        }
    }

    render() {
        if (this.props.type === "number") {
            return (
                <div className="row">
                    <label className="col-sm-2 col-form-label">{this.props.label}</label>
                    <div className="col-sm-10">
                        <div className="form-group bmd-form-group is-filled">
                            <NumberFormat thousandSeparator={this.props.thousandSeparator} prefix={this.props.prefix}
                                decimalScale={this.props.decimal} name={this.props.name} onChange={this.onChange} disabled={this.props.disabled}
                                format={this.props.format} max={255} maxLength={255}
                                autoComplete="off" className="form-control" value={this.props.value} suffix={this.props.suffix} />
                        </div>
                        {this.validationError !== "" && <label style={{ float: "right", color: "#b50000" }} className="error">{this.validationError}</label>}
                    </div>
                </div>
            )
        } else if (this.props.type === "select") {
            return (
                <div className="row">
                    <label className="col-sm-2 col-form-label">{this.props.label}</label>
                    <div className="col-sm-10">
                        <div className="form-group bmd-form-group">
                            <Dropdown
                                placeholder='Selecciona una opcion'
                                fluid
                                search
                                selection
                                clearable
                                key={this.props.name}
                                options={this.props.options?.map((option) => {
                                    return {
                                        key: option.id, value: option.id, text: option.name
                                    }
                                })
                                }
                                onChange={this.onChange}
                                name={this.props.name}
                                disabled={this.props.disabled}
                                value={this.props.value}
                            />
                        </div>
                        {this.validationError !== "" && <label style={{ float: "right", color: "#b50000" }} className="error">{this.validationError}</label>}
                    </div>
                </div>
            )
        } else if (this.props.type === "textarea") {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>{this.props.label}</label>
                            <div className="form-group bmd-form-group">
                                <textarea maxLength={this.props.maxLength} name={this.props.name} className="form-control" rows={5} onChange={this.onChange}
                                    disabled={this.props.disabled} value={this.props.value}></textarea>
                            </div>
                            {this.validationError !== "" && <label style={{ float: "right", color: "#b50000" }} className="error">{this.validationError}</label>}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="row">
                    <label className="col-sm-2 col-form-label">{this.props.label}</label>
                    <div className="col-sm-10">
                        <div className="form-group bmd-form-group is-filled">
                            <input type={this.props.type} name={this.props.name} onChange={this.onChange} disabled={this.props.disabled}
                                autoComplete="off" className="form-control" value={this.props.value} />
                        </div>
                        {this.validationError !== "" && <label style={{ float: "right", color: "#b50000" }} className="error">{this.validationError}</label>}
                    </div>
                </div>
            )
        }
    }
}