import React from "react";
import { Fragment } from "react"
import { Link } from "react-router-dom";

type ContractLinkButtonProps = {
    condition: boolean,
    link: string,
    className: string,
    label: string,
}
export const ContractLinkButton = ({ condition, link, className, label }: ContractLinkButtonProps) => {
    return (
        <Fragment>
            {condition &&
                <Fragment>
                    <Link to={link}>
                        <button className={`btn ${className} btn-md`}>
                            {label}
                            <div className="ripple-container"></div>
                        </button>
                    </Link>
                    <br />
                </Fragment>
            }
        </Fragment>);
}