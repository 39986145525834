import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { sessionService } from '../../services/SessionService';
import swal from 'sweetalert';

interface ViewPaymentProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class ViewPayment extends React.Component<ViewPaymentProps> {

    @observable private payment: any;
    @observable private id: any = "";
    @observable private isLoaded: boolean = false;
    @observable private redirect: boolean = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        this.id = id;
        http.get(`/api/payment/read/${id}`).then((response) => {
            this.payment = response.data;
            this.isLoaded = true;
        })
            .catch(() => {
                toast.error("Error cargando Recibo");
            });
    }
    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/pagos" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Pago: #{this.payment.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../pagos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-4" style={{ textAlign: 'right' }}>
                                        {sessionService.canViewSection("Pagos.delete") &&
                                            <button onClick={() => this.deletePayment(this.payment.id)} style={{ margin: 5 }} type="button" className="btn btn-danger" >
                                                <i className="material-icons">close</i> Borrar
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Cantidad</label>
                                    <div className="col-sm-8">
                                        <strong>${this.payment.amount}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Registrado por</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.created_by_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Registrado el</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.created_date}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Recibo #</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.invoice_id}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Recibo Adicional #</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.additional_id}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Tipo de Pago</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.type}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Direccion</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.address}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Folio</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.folio}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Tarjeta</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.card ? this.payment.card : this.payment.newcard}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Fecha de deposito</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.date}</strong>
                                    </div>
                                </div>
                                {this.payment.type === "Tecnico" &&
                                    <div className="row">
                                        <label className="col-sm-4">Pagado por</label>
                                        <div className="col-sm-8">
                                            <strong>{this.payment.paid_by}</strong>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <label className="col-sm-4">Notas:</label>
                                    <div className="col-sm-8">
                                        <strong>{this.payment.notes}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.payment.picture_id &&
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Imagen</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <img src={`/api/controllers/payment/uploads/${this.payment.picture_id}.jpg`} alt="Imagen de recibo" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> }
            </Fragment >
        )
    }

    private deletePayment = (id: any): void => {
        swal({
            title: "Confirmar acción",
            text: "¿Estas seguro que quieres borrar este pago?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    const deleteObject = JSON.stringify({
                        id
                    });
                    http.post(`/api/payment/delete`, deleteObject)
                        .then((response) => {
                            toast.success("Pago borrado correctamente");
                            this.redirect = true;
                        }).catch((error) => {
                            if (error.includes("foreign")) {
                                toast.error("Error al borrar: Registros ligados a este pago");
                            } else {
                                toast.error("Error borrando pago");
                            }
                        })
                }
            });
    }
}