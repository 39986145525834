import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';

interface ViewHistoryProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class ViewHistory extends React.Component<ViewHistoryProps> {

    @observable private history: any = [];
    @observable private id: any = "";
    @observable private isLoaded: boolean = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    renderData = (contract: any, numero: any): React.ReactNode => {
        const response: any[] = [];
        Object.keys(contract).forEach((key, index) => {
            let cambio = false;
            if (numero !== 0) {
                cambio = contract[key] !== this.history[0][key];
            }
            const value = (
                <div className="row" key={index}>
                    <label className="col-sm-4" style={cambio ? { color: 'red' } : {}}>{key}</label>
                    <div className="col-sm-8">
                        <strong style={cambio ? { color: 'red' } : {}}>{contract[key]}</strong>
                    </div>
                </div>
            );
            response.push(value);
        });
        return response;
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        this.id = id;
        http.get(`/api/contract/log/${id}`).then((response) => {
            this.history = response.data.response.data;
            this.isLoaded = true;
            this.history = this.history.map((value: any, index: any) => {
                return {
                    ...value,
                    collapsed: index === 0 ? false : true
                }
            });
        })
            .catch(() => {
                toast.error("Error cargando Recibo");
            });
    }
    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Historial del Contrato: #{this.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../contratos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                {this.history && this.history.map((contract: any, index: any) => {
                                    return (
                                        <div className="row" key={index}>
                                            <div className="col-sm-12">
                                                <div className="card">
                                                    <div className="card-header card-header-rose" onClick={() => { contract.collapsed = !contract.collapsed }} style={{ cursor: `pointer` }}>
                                                        <h4 className="card-title">{index === 0 ? `Contrato Actual` : `Edicion ${this.history.length - index}`}</h4>
                                                    </div>
                                                    <div className="card-body" style={contract.collapsed ? { display: `none` } : {}}>
                                                        {this.renderData(contract, index)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}
