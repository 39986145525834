import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexPayment from './IndexPayment';  
import NewPayment from './NewPayment';
import RegisterPayment from './RegisterPayment';
import NewBalance from './NewBalance';
import ViewPayment from './ViewPayment';
import PayAdditional from './PayAdditional';
import IndexCard from './cards/IndexCard';
import NewCard from './cards/NewCard';
import EditCard from './cards/EditCard';
import EditPayment from './EditPayment';

interface PaymentProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Payment extends React.Component<PaymentProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/pagos"
            render={(props) => (
              <IndexPayment {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/registrar"
            render={(props) => (
              <RegisterPayment {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/tarjetas"
            render={(props) => (
              <IndexCard {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/tarjetas/nuevo"
            render={(props) => (
              <NewCard {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/tarjetas/:id"
            render={(props) => (
              <EditCard {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/:id"
            render={(props) => (
              <EditPayment {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/ver/:id"
            render={(props) => (
              <ViewPayment {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/adicional/:id"
            render={(props) => (
              <PayAdditional {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/nuevo/:id"
            render={(props) => (
              <NewPayment {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/pagos/afavor/:id"
            render={(props) => (
              <NewBalance {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          
        </Switch>
      </Router>
    )
  }
}