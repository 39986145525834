import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { observable } from 'mobx';
import { sessionService } from '../../services/SessionService';

interface IndexSectorialProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexSectorial extends React.Component<IndexSectorialProps> {

    @observable private sectorial: any;
    @observable private isLoaded = false;
    @observable private sortBy: any = localStorage.getItem("sectorial_sort_by") ? localStorage.getItem("sectorial_sort_by") : "id";
    @observable private sortOrder: any = localStorage.getItem("sectorial_sort_order") ? localStorage.getItem("sectorial_sort_order") : "DESC";
    @observable private search: string = "";
    @observable private results: number = 0;
    @observable private searchResults = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.post('/api/sectorial/read', JSON.stringify({ sort_by: this.sortBy, sort_order: this.sortOrder })).then((response) => {
            this.sectorial = response.data.response;
            this.searchResults = response.data.response;
            this.isLoaded = true;
        }).catch(() => {
            toast.error("Error cargando sectoriales");
        });
    }

    onSearchChange = (event: any) => {
        this.search = event.target.value;
        if (this.search !== "") {
            this.sectorial = this.searchResults.filter((e: any) => { return e.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 });
        } else {
            this.sectorial = this.searchResults;
        }
    }

    resetSearch = () => {
        this.search = "";
        this.results = 0;
    }

    changeSort = (value: string) => {
        this.sortBy = value;
        this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
        localStorage.setItem("sectorial_sort_by", this.sortBy);
        localStorage.setItem("sectorial_sort_order", this.sortOrder);
        http.post('/api/sectorial/read', JSON.stringify({ sort_by: this.sortBy, sort_order: this.sortOrder })).then((response) => {
            this.sectorial = response.data.response;
            this.isLoaded = true;
        }).catch(() => {
            toast.error("Error cargando sectoriales");
        });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="text" name="search" autoComplete="off" placeholder="Buscar..." onChange={this.onSearchChange}
                                                className="form-control" value={this.search} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de sectoriales</h4>
                                <p className="card-category">
                                    Lista de sectoriales disponibles
                                    {
                                        this.results > 0 &&
                                        <span className="pull-right">
                                            Se encontraron {this.results} resultados
                                            <button onClick={this.resetSearch} style={{ height: 17, width: 9, padding: 10, marginLeft: 10 }} type="button" className="btn btn-danger">
                                                <i style={{ top: -9, right: 9 }} className="material-icons">close</i>
                                            </button>
                                        </span>
                                    }
                                </p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-8">
                                        {sessionService.canViewSection("Sectoriales.types") &&
                                            <Link to={`/sectoriales/tipos`}>
                                                <button className="btn btn-info btn-lg">
                                                    <i className="material-icons">account_circle</i> Tipos Sectorial
                                                <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                    <div className="col-sm-4">
                                        {sessionService.canViewSection("Sectoriales.add") &&
                                            <Link to={`/sectoriales/nuevo`}>
                                                <button className="btn btn-info btn-lg pull-right">
                                                    <i className="material-icons">account_circle</i> Nuevo Sectorial
                                                <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th onClick={() => this.changeSort("name")} style={{ cursor: "pointer" }}>Nombre</th>
                                                <th onClick={() => this.changeSort("ip")} style={{ cursor: "pointer" }}>IP</th>
                                                <th onClick={() => this.changeSort("ssid")} style={{ cursor: "pointer" }}>SSID</th>
                                                <th onClick={() => this.changeSort("mhz")} style={{ cursor: "pointer" }}>MHz</th>
                                                <th onClick={() => this.changeSort("channel")} style={{ cursor: "pointer" }}>Canal</th>
                                                <th onClick={() => this.changeSort("contracts")} style={{ cursor: "pointer" }}>Contratos</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.sectorial.map((sector: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{sector.name}</td>
                                                                <td>{sector.ip}</td>
                                                                <td>{sector.ssid}</td>
                                                                <td>{sector.mhz} MHz</td>
                                                                <td>{sector.channel} MHz</td>
                                                                <td>{sector.contracts}</td>
                                                                <td className="td-actions text-right">
                                                                    <Fragment>
                                                                        {sessionService.canViewSection("Sectoriales.edit") &&
                                                                            <Link to={`sectoriales/${sector.id}`}>
                                                                                <button style={{ margin: 5 }} type="button" className="btn btn-success">
                                                                                    <i className="material-icons">edit</i>
                                                                                </button>
                                                                            </Link>
                                                                        }
                                                                        {sessionService.canViewSection("Sectoriales.delete") &&
                                                                            <button onClick={() => this.deleteSectorial(sector.id, index)} style={{ margin: 5 }} type="button" className="btn btn-danger" >
                                                                                <i className="material-icons">close</i>
                                                                            </button>
                                                                        }
                                                                    </Fragment>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    private deleteSectorial = (id: any, index: any): void => {
        swal({
            title: "Confirmar acción",
            text: "¿Estas seguro que quieres borrar el sectorial?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    const deleteObject = JSON.stringify({
                        id
                    });
                    http.post(`/api/sectorial/delete`, deleteObject)
                        .then((response) => {
                            this.sectorial.splice(index, 1);
                            toast.success("Sectorial borrado correctamente");
                        }).catch((error) => {
                            if (error.includes("foreign")) {
                                toast.error("Error al borrar: Registros ligados a este sectorial");
                            } else {
                                toast.error("Error borrando sectorial");
                            }
                        })
                }
            });
    }
}