import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';

interface IndexConnectionProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexConnection extends React.Component<IndexConnectionProps> {

    @observable private contracts: any;
    @observable private isLoaded = false;
    @observable private search: string = "";
    @observable private results: number = 0;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.get('/api/contract/status/Pendiente%20Conexion').then((response) => {
            this.contracts = response.data.response;
            this.isLoaded = true;
        }).catch((error) => {
            toast.error("Error cargando contratos");
        });
    }

    onSearchChange = (event: any) => {
        this.search = event.target.value;
    }

    searchContracts = () => {
        if (this.search.length === 0) {
            toast.error("Debes ingresar un valor");
            return;
        }
        const search = {
            keyword: this.search,
            filter: "Pendiente Conexion"
        }
        http.post('/api/contract/search', JSON.stringify(search)).then((response) => {
            if (response.data.response.length > 0) {
                this.results = response.data.response.length;
                this.contracts = response.data.response;
            } else {
                toast.error("No se encontraron contratos");
            }
        }).catch(() => {
            toast.error("Error cargando contratos");
        });
    }

    enterPressed = (event: any) => {
        let code = event.keyCode || event.which;
        if (code === 13) {
            this.searchContracts();
        }
    }

    resetSearch = () => {
        http.get('/api/contract/page/1').then((response) => {
            this.contracts = response.data.response.data;
            this.search = "";
            this.results = 0;
        }).catch(() => {
            toast.error("Error cargando contratos");
        });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group bmd-form-group is-filled">
                                            <input type="text" name="search" autoComplete="off" placeholder="Buscar..." onChange={this.onSearchChange}
                                                className="form-control" value={this.search} onKeyPress={this.enterPressed} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de conexiones pendientes</h4>
                                <p className="card-category">
                                    Lista de contratos pendientes por conectar
                                    {
                                        this.results > 0 &&
                                        <span className="pull-right">
                                            Se encontraron {this.results} resultados
                                            <button onClick={this.resetSearch} style={{ height: 17, width: 9, padding: 10, marginLeft: 10 }} type="button" className="btn btn-danger">
                                                <i style={{ top: -9, right: 9 }} className="material-icons">close</i>
                                            </button>
                                        </span>
                                    }
                                </p>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Contrato #</th>
                                                <th>Cliente</th>
                                                <th>Status</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.contracts.map((contract: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{contract.id}</td>
                                                                <td>{contract.client}</td>
                                                                <td>{contract.status}</td>
                                                                <td className="td-actions text-right">
                                                                    <Link to={`conexiones/ver/${contract.id}`}>
                                                                        <button style={{ margin: 5 }} type="button" className="btn btn-info">
                                                                            <i className="material-icons">visibility</i>
                                                                        </button>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}