import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import IndexPackage from './IndexPackage';
import NewPackage from './NewPackage';
import EditPackage from './EditPackage';

interface PackageProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Package extends React.Component<PackageProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/paquetes"
            render={(props) => (
              <IndexPackage {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/paquetes/nuevo"
            render={(props) => (
              <NewPackage {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/paquetes/:id"
            render={(props) => (
              <EditPackage {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}