import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import ImageUploader from "react-images-upload";

interface EditSupportVisitProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class EditSupportVisit extends React.Component<EditSupportVisitProps> {

    @observable private support: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private hasCost = false;
    @observable private validationErrors: string[] = [];
    @observable private startingStatus: any = "";
    @observable private contract:any;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/support/read/${id}`).then((response) => {
            this.support = response.data;
            this.startingStatus = this.support.status;
            this.support.service_cost = this.support.service_cost ? this.support.service_cost : "";
            this.hasCost = this.support.service_cost ? true : false;
        }).then(() => {
            http.get(`/api/contract/read/${this.support.contract_id}`).then((response) => {
                this.contract = response.data;
                this.isLoaded = true;
            })
        })
            .catch((error) => {
                toast.error("Error cargando orden de soporte");
            });
    }

    private onDrop2 = (pictureFiles: any) => {
        if (pictureFiles.length > 0) {
            let index = pictureFiles.length - 1; 
            let formData = new FormData(); 
            formData.append('id', this.support.id);
            formData.append('file', pictureFiles[index], pictureFiles[index].name);
            http.post('/api/support/uploadImage', formData)
                .then((res) => {
                    toast.success("Imagen subida con exito");
                 }).catch(() => {
                     toast.error("Error subiendo imagen");
                 });
        }
    }

    private handleChange = (event: any) => {
        if (event.target.name === "visit_comments") {
            this.support.visit_comments = event.target.value;
        } else if (event.target.name === "service_cost") {
            this.support.service_cost = event.target.value;
        } else if (event.target.name === "hasCost") {
            this.hasCost = !this.hasCost;
            if(!this.hasCost) {
                this.support.service_cost = "";
            }
        }
    }

    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {

        this.support.status = "Resuelto";
        if(this.support.service_cost === "") {
            delete this.support.service_cost;
        }

        if(this.support.service_cost) {
            this.support.service_cost = this.support.service_cost.replace("$",'').replace(/,/g, '');
        }

        if (this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        http.post(`/api/support/update`, JSON.stringify(this.support))
            .then(() => {
                toast.success(`Orden de soporte actualizada`);
                this.redirect = true;
            }).catch((error) => {
                if (Array.isArray(error)) {
                    error.forEach((val) => {
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }


    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/visitas" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Datos del Contrato #{this.contract.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <label className="col-sm-4">Cliente</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.client_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Telefono Cliente</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.client_phone}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Teléfono de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_phone}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Relación de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_relationship}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Dirección</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_street}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Colonia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_colonia}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Interior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_interior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Exterior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_exterior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Fecha de corte</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.cutoff_date}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Paquete contratado</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.package_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Forma de cobro</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.payment_type}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Orden de soporte para el contracto #{this.support.contract_id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"><h2>Status: {this.startingStatus}</h2></div>
                                    <div className="col-sm-2">
                                        <Link to={`../soporte`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="content"
                                    label="Descripción del problema"
                                    value={this.support.content}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    disabled={true}
                                />
                                <InputForm
                                    name="response"
                                    label="Resolución"
                                    value={this.support.response}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    disabled={this.startingStatus === "Pendiente Visita" || this.startingStatus === "Resuelto"}
                                />
                                <InputForm
                                    name="visit_comments"
                                    label="Notas de la visita"
                                    value={this.support.visit_comments}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="textarea"
                                    disabled={this.startingStatus === "Resuelto"}
                                />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>El soporte tendra costo?</label>
                                            <div className="form-group bmd-form-group">
                                                <input name="hasCost" type="checkbox" onChange={this.handleChange} checked={this.hasCost}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.hasCost &&
                                    <InputForm
                                        name="service_cost"
                                        label="Costo del servicio"
                                        value={this.support.service_cost}
                                        setValidation={this.setValidation}
                                        required={true}
                                        onChange={this.handleChange}
                                        type="number"
                                        prefix="$"
                                        decimal={2}
                                    />
                                }
                                <ImageUploader
                                    withIcon={true}
                                    buttonText="Subir Imagenes"
                                    onChange={this.onDrop2}
                                    imgExtension={[".jpg"]}
                                    maxFileSize={5242880}
                                    withPreview={true}
                                    label="Sube imagenes del soporte"
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Resolver la Orden de Soporte</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}