import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import EditSectorial from './EditSectorial';
import IndexSectorial from './IndexSectorial';
import NewSectorial from './NewSectorial';
import IndexSectorialTypes from './types/IndexSectorialTypes';
import NewSectorialType from './types/NewSectorialType';

interface SectorialProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Sectorial extends React.Component<SectorialProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/sectoriales"
            render={(props) => (
              <IndexSectorial {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/sectoriales/tipos"
            render={(props) => (
              <IndexSectorialTypes {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/sectoriales/tipos/nuevo"
            render={(props) => (
              <NewSectorialType {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/sectoriales/nuevo"
            render={(props) => (
              <NewSectorial {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/sectoriales/:id"
            render={(props) => (
              <EditSectorial {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}