import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import EditClient from './EditClient';
import NewClient from './NewClient';
import IndexClient from './IndexClient';
import ViewClient from './ViewClient';

interface ClientProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Client extends React.Component<ClientProps> {
  render() {
    return (
      <Router>
        <Switch >
          <Route exact path="/clientes"
            render={(props) => (
              <IndexClient {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/clientes/nuevo"
            render={(props) => (
              <NewClient {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          
          <Route exact path="/clientes/ver/:id"
            render={(props) => (
              <ViewClient {...props} changeToggle={this.props.changeToggle} />
            )}>

          </Route>
          <Route exact path="/clientes/:id"
            render={(props) => (
              <EditClient {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}