import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { observable } from 'mobx';

interface IndexSectorialTypesProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexSectorialTypes extends React.Component<IndexSectorialTypesProps> {

    @observable private types: any;
    @observable private isLoaded = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.get(`/api/sectorial/types`).then((response:any) => {
            this.types = response.data.response;
            this.isLoaded = true;
        }).catch(() => {
            toast.error("Error cargando sectoriales");
        });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Lista de sectoriales</h4>
                                <p className="card-category">Lista de sectoriales disponibles</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`/sectoriales/tipos/nuevo`}>
                                            <button className="btn btn-info btn-lg  pull-right">
                                                <i className="material-icons">account_circle</i> Nuevo Tipo
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead className=" text-primary">
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Capacidad</th>
                                                <th className="text-right">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.types.map((sector: any, index: any) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{sector.name}</td>
                                                                <td>{sector.capacity}</td>
                                                                <td className="td-actions text-right">

                                                                    <button onClick={() => this.deleteSectorial(sector.id, index)} style={{ margin: 5 }} type="button" className="btn btn-danger" >
                                                                        <i className="material-icons">close</i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    private deleteSectorial = (id: any, index: any): void => {
        swal({
            title: "Confirmar acción",
            text: "¿Estas seguro que quieres borrar el tipo de sectorial?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    const deleteObject = JSON.stringify({
                        id
                    });
                    http.post(`/api/sectorial/deletetype`, deleteObject)
                        .then(() => {
                            this.types.splice(index, 1);
                            toast.success("Tipo de sectorial borrado correctamente");
                        }).catch((error:any) => {
                            if (error.includes("foreign")) {
                                toast.error("Error al borrar: Registros ligados a este tipo de sectorial");
                            } else {
                                toast.error("Error borrando sectorial");
                            }
                        })
                }
            });
    }
}