import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import ImageUploader from "react-images-upload";

interface AddImagesProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class AddImages extends React.Component<AddImagesProps> {

    @observable private contract: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors: string[] = [
        "due_balance"
    ];
    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;

        http.get(`/api/contract/read/${id}`).then((response) => {
            this.contract = response.data;
            if(this.contract.due_balance !== 0){
                this.validationErrors = [];
            }
        })
        .then(() => {
            this.isLoaded = true;
        })
        .catch((error) => {
            toast.error("Error cargando Informacion");
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.contract[name] = value;
    }

    
    private setValidation = (value: boolean, name: string): void => {
        const error = this.validationErrors.indexOf(name);
        if (value && error === -1) {
            this.validationErrors.push(name);
        } else if (!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        this.redirect = true;
    }

    private onDrop2 = (pictureFiles: any) => {
            if (pictureFiles.length > 0) {
                pictureFiles.forEach((file: any, index: number) => {
                let formData = new FormData();
                formData.append('id', this.contract.id);
                formData.append('file', pictureFiles[index], pictureFiles[index].name);
                http.post('/api/contract/uploadImage', formData)
                    .then((res) => {
                        toast.success("Imagen subida con exito");
                    }).catch(() => {
                        toast.error("Error subiendo imagen");
                    });
                });
                
            }
        }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to={`/contratos/ver/${this.contract.id}`} />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Agregar imagenes a contrato {this.contract && this.contract.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`/contratos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <ImageUploader
                                                                    withIcon={true}
                                                                    buttonText="Subir Imagenes"
                                                                    onChange={this.onDrop2}
                                                                    imgExtension={[".jpg", "jpeg"]}
                                                                    maxFileSize={5242880}
                                                                    withPreview={true}
                                                                    label="Sube imagenes de contrato"
                                                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Volver al contrato </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}