import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import InputForm from '../site/layout/InputForm';
import { sessionService } from '../../services/SessionService';

interface NewUserProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class NewUser extends React.Component<NewUserProps> {

    @observable private user: any = {
        username: "",
        name:"",
        password: "",
        role_id: "",
        phone: ""
    };
    @observable private roles: any;
    @observable private isLoaded = false;
    @observable private redirect = false;
    @observable private validationErrors:string[] = [];

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.get(`/api/role/read`).then((response) => {
            this.roles = response.data.response;
            this.isLoaded = true;
        })
        .catch((error) => {
            toast.error("Error cargando usuarios");
        });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.user[name] = value;
    }

    private setValidation = (value:boolean, name:string):void =>{
        const error = this.validationErrors.indexOf(name);
        if(value && error === -1) {
            this.validationErrors.push(name);
        } else if(!value && error !== -1) {
            this.validationErrors.splice(error, 1);
        }
    }

    private onSubmit = (): void => {
        if(this.validationErrors.length > 0) {
            toast.error("Hay un error en tu formulario");
            return;
        }

        this.user.created_by = sessionService.getUserInfo().id;
        // eslint-disable-next-line
        this.user.phone = this.user.phone.replace(/\-/g, '');
        
        http.post(`/api/user/create`, JSON.stringify(this.user))
            .then(() => {
                toast.success(`Usuario agregado`);
                this.redirect = true;
            }).catch((error)=>{
                if(Array.isArray(error)) {
                    error.forEach((val) =>{
                        toast.error(val);
                    })
                } else {
                    toast.error(error);
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if(this.redirect) {
            return <Redirect to="/usuarios" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">{this.user.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../usuarios`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <InputForm
                                    name="username"
                                    label="Usuario"
                                    value={this.user.username}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="name"
                                    label="Nombre"
                                    value={this.user.name}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="text"
                                />
                                <InputForm
                                    name="password"
                                    label="Password"
                                    value={this.user.password}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="password"
                                />
                                <InputForm
                                    name="phone"
                                    label="Teléfono"
                                    value={this.user.phone}
                                    setValidation={this.setValidation}
                                    required={true}
                                    onChange={this.handleChange}
                                    type="number"
                                    format={"###-###-####"}
                                />
                                <InputForm
                                    name="role_id"
                                    label="Rol"
                                    value={this.user.role_id}
                                    setValidation={this.setValidation}
                                    required={false}
                                    onChange={this.handleChange}
                                    type="select"
                                    options={this.roles}
                                />
                                <button onClick={this.onSubmit} type="submit" className="btn btn-primary pull-right">Crear Usuario</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}