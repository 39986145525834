export function isAuthMissing(): boolean {
    return localStorage.getItem('token') == null;
}

export function isAuthRequired(url?: string): boolean {
    if(!url) {
        return false;
    }
    const noAuthUrls = [
        /\/api\/user\/login/
    ];

    for (let i = 0; i < noAuthUrls.length; i++) {
        const noAuthUrl = noAuthUrls[i];
        if (url.toLowerCase().match(noAuthUrl) != null) {
            return false;
        }
    }

    return true;
}

export function apiUrl(): any {
    const prod = {
        url: {
            API_URL: process.env.REACT_APP_URL
        }
    };
    const dev = {
        url: {
            API_URL: "https://localhost"
        }
    };
    return process.env.NODE_ENV === "development" ? dev : prod;
}