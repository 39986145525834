import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'
import swal from 'sweetalert';
import { sessionService } from '../../services/SessionService';
import InputForm from '../site/layout/InputForm';

interface ViewConnectionProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

@observer export default class ViewConnection extends React.Component<ViewConnectionProps> {

    @observable private contract: any;
    @observable private isLoaded = false;
    @observable private redirect = false;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/contract/read/${id}`).then((response) => {
            this.contract = response.data;
            this.contract.status = "Activo";
            this.contract.connected_by = sessionService.getUserInfo().id;
            this.contract.updated_by = sessionService.getUserInfo().id;
            this.isLoaded = true;
        })
            .catch((error) => {
                toast.error("Error cargando contrato");
            });
    }

    private handleChange = (event: any) => {
        const { name, value } = event.target;
        this.contract[name] = value;
    }


    private onChangeStatus = () => {
        swal({
            title: "Confirmar acción",
            text: "¿Quieres cambiar el estatus a Activo?",
            icon: "warning",
            dangerMode: true
        })
            .then(willSave => {
                if (willSave) {
                    http.post(`/api/contract/update`, JSON.stringify(this.contract))
                        .then(() => {
                            toast.success(`Contrato actualizado`);
                            this.redirect = true;
                        }).catch((error) => {
                            if (Array.isArray(error)) {
                                error.forEach((val) => {
                                    toast.error(val);
                                })
                            } else {
                                toast.error(error);
                            }
                        });
                }
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        if (this.redirect) {
            return <Redirect to="/conexiones" />
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12 col-lg-8">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Contrato #{this.contract.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../conexiones`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Cliente</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.client_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Teléfono de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_phone}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Relación de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_relationship}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Dirección</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_street}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Colonia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_colonia}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Interior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_interior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Exterior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_exterior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Fecha de corte</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.cutoff_date}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Paquete contratado</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.package_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Forma de cobro</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.payment_type}</strong>
                                    </div>
                                </div>
                                {this.contract.sectorial_name &&
                                    <div className="row">
                                        <label className="col-sm-4">Sectorial</label>
                                        <div className="col-sm-8">
                                            <strong>{this.contract.sectorial_name}</strong>
                                        </div>
                                    </div>
                                }
                                {this.contract.mac &&
                                    <div className="row">
                                        <label className="col-sm-4">MAC Address</label>
                                        <div className="col-sm-8">
                                            <strong>{this.contract.mac}</strong>
                                        </div>
                                    </div>
                                }
                                {this.contract.ip &&
                                    <div className="row">
                                        <label className="col-sm-4">IP</label>
                                        <div className="col-sm-8">
                                            <h3>{this.contract.ip}</h3>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="card">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                                        <button onClick={this.onChangeStatus} className="btn btn-info btn-lg">
                                            Cambiar status a activo
                                            <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                                        <InputForm
                                            name="notes_connection"
                                            label="Notas de conexion"
                                            value={this.contract.notes_connection}
                                            onChange={this.handleChange}
                                            type="textarea"
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-info card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">history</i>
                                </div>
                                <h3 className="card-title">Historial del Contrato</h3>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <label className="col-sm-4">Status:</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.status}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Contrato creado el:</label>
                                    <div className="col-sm-8">
                                        <strong>{formatRelative(new Date(this.contract.created_date), new Date(), { locale: es })}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Notas de Creacion:</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.notes_creation}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Notas de Conexion:</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.notes_connection}</strong>
                                    </div>
                                </div>
                                {this.contract.installed_by_name &&
                                    <div className="row">
                                        <label className="col-sm-4">Instalado por:</label>
                                        <div className="col-sm-8">
                                            <strong>{this.contract.installed_by_name}</strong>
                                        </div>
                                    </div>
                                }
                                {this.contract.installation_date &&
                                    <div className="row">
                                        <label className="col-sm-4">Instalado por:</label>
                                        <div className="col-sm-8">
                                            <strong>{formatRelative(new Date(this.contract.installation_date), new Date(), { locale: es })}</strong>
                                        </div>
                                    </div>
                                }
                                {this.contract.notes_installation &&
                                    <div className="row">
                                        <label className="col-sm-4">Notas de instalación:</label>
                                        <div className="col-sm-8">
                                            <strong>{this.contract.notes_installation}</strong>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}