import React from "react";
import { Fragment } from "react"
import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'

type EditionHisotryProps = {
    editionHistory: any,
    loadHistory: ()=>any
}
export const EditionHistory = ({ editionHistory, loadHistory }: EditionHisotryProps) => {
    return (
        <Fragment>
            {!editionHistory &&
                <button onClick={loadHistory} className="btn btn-warning btn-lg">
                    Cargar Historial de edicion
                    <div className="ripple-container"></div>
                </button>
            }
            {
                editionHistory && editionHistory.length === 0 &&
                <h2>No hay historial de edicion</h2>
            }
            {
                editionHistory && editionHistory.map((history: any, index: any) => {
                    const action = history.action === "update" ? "edito" : "quito cargo de desconexion";
                    return (
                        <Fragment key={index}>
                            <span><strong>{`${history.name} ${action} ${formatRelative(new Date(history.date), new Date(), { locale: es })}`}</strong><br /></span>
                        </Fragment>
                    )
                })
            }
        </Fragment>);
}