import { http } from '../services/HttpInterceptor';
import { observable } from 'mobx';
import { toast } from 'react-toastify';

class SessionService {
    @observable private _isLogged: boolean = false;
    @observable private _userInfo:any;
    @observable private _showLogin: boolean = false;
    @observable private tokenUpdated:boolean = false;

    constructor() {
        this.init();
    }

    private init = () =>{
        if(localStorage.getItem('token')){
            http.get('/api/user/getUserData').then((response)=>{
                this.fillInfoData(response.data);
                this._isLogged = true
            }).catch(()=> {
                toast.error("Sesión invalida");
                this._showLogin = true;
                this._isLogged = false;
                localStorage.removeItem('token')
            })
        } else {
            this._isLogged = false;
            this._showLogin = true;
        }
    }
    public isLogged(): boolean {
        return this._isLogged;
    }

    public showLogin(): boolean {
        return this._showLogin;
    }

    public logOut():void {
        this._isLogged = false;
    }

    public fillInfoData = (data:any) => {
        this._isLogged = true;
        this._userInfo = data;
    }

    public getUserInfo = () => {
        return this._userInfo;
    }

    public canViewSection = (name: any) => {
        return this.getUserInfo() != null && sessionService.getUserInfo().claims.indexOf(name) >= 0;
    }

    setTokenUpdated = () => {
        this.tokenUpdated = true;
    }

    isTokenUpdated = () => {
        return this.tokenUpdated;
    }

}
export const sessionService = new SessionService();