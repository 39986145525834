import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link, Redirect
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'
import { apiUrl } from '../../services/HttpInterceptorUtil';
import swal from 'sweetalert';
import { createBrowserHistory } from "history";
import { sessionService } from '../../services/SessionService';
import { EditionHistory } from './sub-components/EditionHistory';
import { Photos } from './sub-components/Photos';
import { ContractActionButton } from './sub-components/ContractActionButton';
import { ContractLinkButton } from './sub-components/ContractLinkButton';
import { ContractConditionalBlock } from './sub-components/ContractConditionalBlock';

interface ViewContractProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

const history = createBrowserHistory({ forceRefresh: true });

@observer export default class ViewContract extends React.Component<ViewContractProps> {

    @observable private contract: any;
    @observable private isLoaded = false;
    @observable private contract_invoices: any;
    @observable private support_tickets: any;
    @observable private payments: any;
    @observable private photos: any;
    @observable private redirect: boolean = false;
    @observable private editionHistory: any;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        http.get(`/api/contract/read/${id}`).then((response) => {
            this.contract = response.data;
        })
            .then(() => {
                return http.get(`/api/invoice/contract/${id}`).then((response) => {
                    this.contract_invoices = response.data;
                });
            })
            .then(() => {
                return http.get(`/api/payment/contract/${id}`).then((response) => {
                    this.payments = response.data;
                });
            })
            .then(() => {
                return http.get(`/api/support/contract/${id}`).then((response) => {
                    this.support_tickets = response.data.response;
                    this.isLoaded = true;
                });
            })
            .catch((error) => {
                toast.error("Error cargando contrato");
            });
    }

    removeDisconnect = () => {
        swal({
            title: "Confirmar acción",
            text: "¿Esto eliminara el cargo de reconexion y modificara recibos activos?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willSave => {
                if (willSave) {
                    const obj = JSON.stringify({
                        id: this.contract.id,
                        updated_by: sessionService.getUserInfo().id
                    });
                    http.post("/api/invoice/disconnection", obj)
                        .then(() => {
                            toast.success("Se quito el cargo de reconexion");
                        })
                }
            });
    }

    onPay = () => {
        history.push(`/pagos/adicional/${this.contract.id}`);
    }

    loadPhotos = () => {
        http.get(`/api/contract/images/${this.contract.id}`).then((response) => {
            this.photos = response.data;
        });
    }

    loadHistory = () => {
        http.get(`/api/contract/history/${this.contract.id}`).then((response) => {
            this.editionHistory = response.data.response;
        });
    }

    onSupport = () => {
        history.push(`/soporte/${this.contract.id}/nuevo`);
    }

    onAddBalance = () => {
        history.push(`/pagos/afavor/${this.contract.id}`);
    }

    onAddNegativeBalance = () => {
        history.push(`/contratos/negativo/${this.contract.id}`);
    }

    onAddInvoivce = () => {
        history.push(`/recibos/crear/${this.contract.id}`);
    }

    render() {
        if (this.redirect) {
            return <Redirect to="/contratos" />
        }

        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Contrato #{this.contract.id}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-2">
                                        {sessionService.canViewSection("Contratos.edit") &&
                                            <Link to={`../../contratos/edit/${this.contract.id}`}>
                                                <button className="btn btn-success btn-sm  pull-right">
                                                    <i className="material-icons">edit</i>
                                                    <div className="ripple-container"></div>
                                                </button>
                                            </Link>
                                        }
                                    </div>
                                    <div className="col-sm-2">
                                        <Link to={`../../contratos`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Cliente</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.client_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Telefono Cliente</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.client_phone}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Otros Telefonos</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.other_phones}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Teléfono de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_phone}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Relación de Referencia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.reference_relationship}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Dirección</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_street}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Colonia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_colonia}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Interior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_interior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Exterior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_exterior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Codigo Postal</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_zipcode}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Referencia de dirección</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.address_reference}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Fecha de corte</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.cutoff_date}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Tipo de casa</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.house_type}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Paquete contratado</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.package_name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Forma de cobro</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.payment_type}</strong>
                                    </div>
                                </div>
                                {this.contract.sectorial_name &&
                                    <div className="row">
                                        <label className="col-sm-4">Sectorial</label>
                                        <div className="col-sm-8">
                                            <strong>{this.contract.sectorial_name}</strong>
                                        </div>
                                    </div>
                                }
                                {this.contract.mac &&
                                    <div className="row">
                                        <label className="col-sm-4">MAC</label>
                                        <div className="col-sm-8">
                                            <strong>{this.contract.mac}</strong>
                                        </div>
                                    </div>
                                }
                                {this.contract.ip &&
                                    <div className="row">
                                        <label className="col-sm-4">IP</label>
                                        <div className="col-sm-8">
                                            <h3>{this.contract.ip}</h3>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                                        {this.contract.status !== "Cerrado" &&
                                            <Fragment>
                                                <ContractActionButton condition={sessionService.canViewSection("Contratos.disconnect_charge") && this.contract.disconnect_charge === "1"}
                                                    onClick={this.removeDisconnect} className='btn-danger' label='Quitar cargo de reconexion' />

                                                <ContractActionButton condition={sessionService.canViewSection("Contratos.balance")}
                                                    onClick={this.onAddBalance} className='btn-info' label='Agregar saldo a favor' />

                                                <ContractActionButton condition={sessionService.canViewSection("Contratos.debitbalance")}
                                                    onClick={this.onAddNegativeBalance} className='btn-danger' label='Fijar saldo deudor' />

                                                <ContractActionButton condition={sessionService.canViewSection("Contratos.support")}
                                                    onClick={this.onSupport} className='btn-warning' label='Crear orden de soporte' />

                                                <ContractActionButton condition={sessionService.canViewSection("Contratos.additional")}
                                                    onClick={this.onPay} className='btn-info' label='Pagar recibo adicional' />

                                                <ContractActionButton condition={sessionService.canViewSection("Contratos.addinvoice")}
                                                    onClick={this.onAddInvoivce} className='btn-success' label='Crear recibo al contrato' />

                                                <ContractLinkButton condition={sessionService.canViewSection("Contratos.addimages")}
                                                    link={`../imagenes/${this.contract.id}`} className='btn-success' label='Agregar imagenes' />    

                                                <ContractLinkButton condition={sessionService.canViewSection("Contratos.close")}
                                                    link={`../cancelar/${this.contract.id}`} label="Dar de baja contrato" className='btn-danger' />
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                                        <h3>Saldo a favor</h3>
                                        <h2>${this.contract.balance}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-12" style={{ textAlign: "center" }}>
                                        <h3>Saldo deudor</h3>
                                        <h2>${this.contract.due_balance}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-info card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">history</i>
                                </div>
                                <h3 className="card-title">Historial del Contrato</h3>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <label className="col-sm-4">Status:</label>
                                    <div className="col-sm-8">
                                        <h2>{this.contract.status}</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Contrato creado el:</label>
                                    <div className="col-sm-8">
                                        <strong>{formatRelative(new Date(this.contract.created_date), new Date(), { locale: es })}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Notas de Creacion:</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.notes_creation}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Notas de Conexion:</label>
                                    <div className="col-sm-8">
                                        <strong>{this.contract.notes_connection}</strong>
                                    </div>
                                </div>
                                <ContractConditionalBlock value={this.contract.installed_by_name} label="Instalado por" />
                                <ContractConditionalBlock value={this.contract.installation_date} label="Instalado el" isDate={true} />
                                <ContractConditionalBlock value={this.contract.notes_installation} label="Notas de instalación" />
                                <ContractConditionalBlock value={this.contract.connected_by_name} label="Conectado por" />
                                <ContractConditionalBlock value={this.contract.connection_date} label="Conectado el" isDate={true} />
                                <ContractConditionalBlock value={this.contract.closed_by_name} label="Cerrado por" />
                                <ContractConditionalBlock value={this.contract.closed_date} label="Cerrado el" isDate={true} />
                                <ContractConditionalBlock value={this.contract.retired_by} label="Retirado por" />
                                <ContractConditionalBlock value={this.contract.equipment_retired} label="Equipo Retireado" />
                                <ContractConditionalBlock value={this.contract.retired_date} label="Fecha Retireado" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-info card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">help</i>
                                </div>
                                <h3 className="card-title">Ordenes de Soporte</h3>
                            </div>
                            <div className="card-body table-responsive">
                                <table className="table table-hover">
                                    <thead className="text-warning">
                                        <tr><th>#</th>
                                            <th>Status</th>
                                            <th>Creado</th>
                                            <th>Ver</th>
                                        </tr></thead>
                                    <tbody>
                                        {
                                            this.support_tickets && this.support_tickets.map((support: any, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{support.id}</td>
                                                        <td>{support.status}</td>
                                                        <td>{support.created_date}</td>
                                                        <td className="td-actions">
                                                            <button type="button" title="" onClick={() => this.goSupport(support.id)} className="btn btn-primary btn-link btn-sm">
                                                                <i className="material-icons">visibility</i>
                                                                <div className="ripple-container"></div>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-success card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">monetization_on</i>
                                </div>
                                <h3 className="card-title">Historial de Recibos</h3>
                            </div>
                            <div className="card-body table-responsive">
                                <table className="table table-hover">
                                    <thead className="text-warning">
                                        <tr><th>#</th>
                                            <th>Vence</th>
                                            <th>Cantidad</th>
                                            <th>Tipo</th>
                                            <th>Estatus</th>
                                            <th>Recibo</th>
                                        </tr></thead>
                                    <tbody>
                                        {
                                            this.contract_invoices && this.contract_invoices.response.data.map((invoice: any, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{invoice.id}</td>
                                                        <td>{invoice.due_date}</td>
                                                        <td>${invoice.amount}</td>
                                                        <td>{invoice.type}</td>
                                                        <td>{invoice.status}</td>
                                                        <td className="td-actions">
                                                            <button type="button" title="" onClick={() => this.viewInvoice(invoice.id)} className="btn btn-primary btn-link btn-sm">
                                                                <i className="material-icons">visibility</i>
                                                                <div className="ripple-container"></div>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-success card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">payments</i>
                                </div>
                                <h3 className="card-title">Historial de Pagos</h3>
                            </div>
                            <div className="card-body table-responsive">
                                <table className="table table-hover">
                                    <thead className="text-warning">
                                        <tr><th>#</th>
                                            <th>Tipo</th>
                                            <th>Cantidad</th>
                                            <th>Fecha</th>
                                            <th>Ver</th>
                                        </tr></thead>
                                    <tbody>
                                        {
                                            this.payments && this.payments.response.data.map((payment: any, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{payment.id}</td>
                                                        <td>{payment.type}</td>
                                                        <td>${payment.amount}</td>
                                                        <td>{payment.created_date}</td>
                                                        <td className="td-actions">
                                                            <button type="button" title="" onClick={() => this.viewPayment(payment.id)} className="btn btn-primary btn-link btn-sm">
                                                                <i className="material-icons">visibility</i>
                                                                <div className="ripple-container"></div>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-header card-header-success card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">perm_media</i>
                                </div>
                                <h3 className="card-title">Imagenes</h3>
                            </div>
                            <div className="card-body">
                                <div className="col-sm-12">
                                    <Photos photos={this.photos} loadPhotos={this.loadPhotos} apiUrl={apiUrl} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-header card-header-success card-header-icon">
                                <div className="card-icon">
                                    <i className="material-icons">history</i>
                                </div>
                                <h3 className="card-title">Historial de edicion</h3>
                            </div>
                            <div className="card-body">
                                <div className="col-sm-12">
                                    <Link to={`../../contratos/historia/${this.contract.id}`}>
                                        <button className="btn btn-info btn-md">
                                            Historial Contrato
                                            <div className="ripple-container"></div>
                                        </button>
                                    </Link>
                                    <br />
                                    <EditionHistory editionHistory={this.editionHistory} loadHistory={this.loadHistory} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }

    private viewInvoice = (id: any) => {
        history.push(`/recibos/ver/${id}`);
    }

    private viewPayment = (id: any) => {
        history.push(`/pagos/ver/${id}`);
    }

    private goSupport = (id: any) => {
        history.push(`/soporte/${id}`);
    }
}