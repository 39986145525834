  export const MENU:any = [
    { name: "Inicio", menuName: "Inicio" ,icon: "dashboard", url: "/" },
    { name: "Clientes", menuName: "Clientes",icon: "people", url: "/clientes", actions: ["add", "edit", "view", "delete"] },
    { name: "Contratos", menuName: "Contratos",icon: "analytics", url: "/contratos", actions: ["add", "edit", "view", "balance", "debitbalance", "support", "additional", "close", "cutoff", "disconnect_charge", "addinvoice", "addimages"] },
    { name: "Pagos", menuName: "Pagos",icon: "payments", url: "/pagos" , actions: ["view", "edit", "delete", "barcode", "card"] },
    { name: "Recibos", menuName: "Recibos", icon: "receipt", url: "/recibos", actions: ["add", "view", "edit", "delete", "print", "pay"] },
    { name: "Sectoriales", menuName: "Sectoriales",icon: "library_books", url: "/sectoriales", actions: ["add", "edit", "delete", "types"] },
    { name: "Instalaciones", menuName: "Instalaciones", icon: "construction", url: "/instalaciones" },
    { name: "Conexiones", menuName: "Conexiones", icon: "router", url: "/conexiones"  },
    { name: "Ordenes de Soporte", menuName: "Órdenes de Soporte", icon: "help", url: "/soporte" },
    { name: "Visitas de Soporte", menuName: "Visitas de Soporte", icon: "tour", url: "/visitas" },
    { name: "Paquetes", menuName: "Paquetes", icon: "folder", url: "/paquetes", actions: ["add", "edit", "delete"] },
    { name: "Antenas",  menuName: "Antenas",icon: "settings_input_antenna", url: "/antenas", actions: ["add", "edit", "delete"] },
    { name: "Inventario",  menuName: "Inventario",icon: "analytics", url: "/inventario", actions: ["add", "edit", "delete"] },
    { name: "Roles",  menuName: "Roles",icon: "wysiwyg", url: "/roles", actions: ["add", "edit", "delete"] },
    { name: "Reporte",  menuName: "Reporte",icon: "report", url: "/reporte", actions: ["view"] },
    { name: "Usuarios",menuName: "Usuarios", icon: "person", url: "/usuarios", actions: ["add", "edit", "delete"] }
  ]