import React from 'react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router, RouteComponentProps, Switch, Route
} from "react-router-dom";
import NewContract from './NewContract';
import IndexContract from './IndexContract';
import ViewContract from './ViewContract';
import EditContract from './EditContract';
import IndexCutOffs from './cutoffs/IndexCutOff';
import NewCutOff from './cutoffs/NewCutOff';
import EditCutOff from './cutoffs/EditCutOff';
import ViewCutOff from './cutoffs/ViewCutOff';
import CancelContract from './CancelContract';
import AddNegative from './AddNegative';
import ViewHistory from './ViewHistory';
import AddImages from './AddImages';

interface ContractProps extends RouteComponentProps {
  changeToggle(action?: boolean): void
}

@observer export default class Contract extends React.Component<ContractProps> {
  render() {
    return (
      <Router>
        <Switch >
        <Route exact path="/contratos"
            render={(props) => (
              <IndexContract {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/nuevo/:id?"
            render={(props) => (
              <NewContract {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/ver/:id"
            render={(props) => (
              <ViewContract {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/historia/:id"
            render={(props) => (
              <ViewHistory {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/cortes"
            render={(props) => (
              <IndexCutOffs {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/cortes/nuevo"
            render={(props) => (
              <NewCutOff {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/cortes/ver/:id"
            render={(props) => (
              <ViewCutOff {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/cortes/:id"
            render={(props) => (
              <EditCutOff {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/cancelar/:id"
            render={(props) => (
              <CancelContract {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/edit/:id"
            render={(props) => (
              <EditContract {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/negativo/:id"
            render={(props) => (
              <AddNegative {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/imagenes/:id"
            render={(props) => (
              <AddImages {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
          <Route exact path="/contratos/filtro/:filter"
            render={(props) => (
              <IndexContract {...props} changeToggle={this.props.changeToggle} />
            )}>
          </Route>
        </Switch>
      </Router>
    )
  }
}