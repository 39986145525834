import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../services/HttpInterceptor';
import {
    RouteComponentProps, Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import { createBrowserHistory } from "history";

interface ViewClientProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}

const history = createBrowserHistory({ forceRefresh: true });
@observer export default class ViewClient extends React.Component<ViewClientProps> {

    @observable private client: any;
    @observable private isLoaded = false;
    @observable private contracts: any;
    @observable private id: number = 0;

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        const match = this.props.match;
        const id = (match as any).params.id;
        this.id = id;
        http.get(`/api/client/read/${id}`).then((response) => {
            this.client = response.data;
        }).then(() => {
            http.get(`/api/contract/user/${id}`).then((response) => {
                this.contracts = response.data.response;
                this.isLoaded = true;
            })
        })
            .catch(() => {
                toast.error("Error cargando cliente");
            });
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Cliente: {this.client.name}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-2">
                                        <Link to={`../../clientes/${this.id}`}>
                                            <button className="btn btn-success btn-sm  pull-right">
                                                <i className="material-icons">edit</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="col-sm-2">
                                        <Link to={`../../clientes`}>
                                            <button className="btn btn-danger btn-sm  pull-right">
                                                <i className="material-icons">highlight_off</i>
                                                <div className="ripple-container"></div>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Nombre</label>
                                    <div className="col-sm-8">
                                        <strong>{this.client.name}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Calle</label>
                                    <div className="col-sm-8">
                                        <strong>{this.client.address_street}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Colonia</label>
                                    <div className="col-sm-8">
                                        <strong>{this.client.address_colonia}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Interior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.client.address_interior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Número Exterior</label>
                                    <div className="col-sm-8">
                                        <strong>{this.client.address_exterior}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Teléfono</label>
                                    <div className="col-sm-8">
                                        <strong>{this.client.phone}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-4">Creado por</label>
                                    <div className="col-sm-8">
                                        <strong>{this.client.created_by_name}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="card">
                            <div className="card-header card-header-rose card-header-text">
                                <div className="card-text">
                                    <h4 className="card-title">Contratos: {this.contracts.total}</h4>
                                </div>
                            </div>
                            <div className="card-body ">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div onClick={() => history.push(`/contratos/nuevo/${this.id}`)} className="col-sm-2">
                                        <button className="btn btn-info btn-lg  pull-right">
                                            <i className="material-icons">folder</i> Nuevo Contrato
                                                <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                </div>
                                <table className="table">
                                    <tbody>
                                        {
                                            this.contracts.data.map((contract: any, key:any) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>Contrato # {contract.id}</td>
                                                        <td className="td-actions text-right">
                                                            <button type="button" onClick={() => history.push(`/contratos/ver/${contract.id}`)} title="" className="btn btn-primary btn-sm" data-original-title="Edit Task">
                                                                <i className="material-icons">visibility</i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}