import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { http } from '../../../services/HttpInterceptor';
import {
    RouteComponentProps
} from "react-router-dom";
import { toast } from 'react-toastify';
import { observable } from 'mobx';
import DatePicker from "react-datepicker";
import { apiUrl } from '../../../services/HttpInterceptorUtil';
interface IndexPrintInvoiceProps extends RouteComponentProps {
    changeToggle(action?: boolean): void
}
@observer export default class IndexPrintInvoice extends React.Component<IndexPrintInvoiceProps> {

    @observable private fromDate: Date = new Date();
    @observable private toDate: Date = new Date();
    @observable private isLoaded = false;
    @observable private cutoff_date: any = "";
    @observable private cutoff_dates: any = [];
    @observable private type: any = "";
    @observable private payment_type: any ="";
    @observable private payment_status:any = "";

    @observable private types: any = [{
        name: "Mensualidad", value: "Mensualidad"
    }, {
        name: "Adicional", value: "Adicional"
    }, {
        name: "Antena", value: "Antena"
    }];

    @observable private payment_types: any = [{
        name: "Todos", value: "Todos"
    }, {
        name: "COBRADOR", value: "COBRADOR"
    }, {
        name: "DEPOSITO", value: "DEPOSITO"
    }, {
        name: "TRANSFERENCIA", value: "TRANSFERENCIA"
    }];

    @observable private status: any =[{
        name: "Pendiente", value: "Pendiente"
    },{
        name: "Pagado", value: "Pagado"
    },{
        name: "Vencido", value: "Vencido"
    },{
        name: "Cancelado", value: "Cancelado"
    },];

    onChange = (event: any) => {
        if (event.target.name === "cutoff_date") {
            this.cutoff_date = event.target.value;
        } else if (event.target.name === "type") {
            this.type = event.target.value;
        } else if (event.target.name === "payment_type") {
            this.payment_type = event.target.value;
        } else if (event.target.name === "payment_status") {
            this.payment_status = event.target.value;
        }
    }

    componentDidMount() {
        this.props.changeToggle(true);
        this.init();
    }

    init = () => {
        http.get('/api/cutoff/read').then((response) => {
            this.cutoff_dates = response.data.response;
            this.cutoff_dates.push({
                date: "AdicionalAntena"
            })
            this.isLoaded = true;
        })
            .catch((error) => {
                toast.error("Error cargando recibos");
            });
    }

    setStartDate = (date: any, input: string) => {
        if (input === "from") {
            this.fromDate = date;
        } else {
            this.toDate = date;
        }
    }

    print = () => {
        if(this.cutoff_date === "" || this.type === "" || this.payment_type === "") {
            toast.error("Todos los campos son requeridos");
            return;
        }

        window.open(`${apiUrl().url.API_URL}/api/invoice/print/${this.type}/${this.cutoff_date}/${this.payment_type}/${this.payment_status}/${this.fromDate.getFullYear()}-${("0" + (this.fromDate.getMonth() + 1)).slice(-2)}-${("0" + this.fromDate.getDate()).slice(-2)} 00:00:00/${this.toDate.getFullYear()}-${("0" + (this.toDate.getMonth() + 1)).slice(-2)}-${("0" + this.toDate.getDate()).slice(-2)} 23:59:59`);
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header card-header-primary">
                                <h4 className="card-title ">Imprimir recibos</h4>
                                <p className="card-category">Imprimir recibos en baches</p>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-10"></div>
                                    <div className="col-sm-2">
                                        <button onClick={this.print} className="btn btn-warning btn-md  pull-right">
                                            <i className="material-icons">print</i> Imprimir
                                            <div className="ripple-container"></div>
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <div className="form-group bmd-form-group">
                                                <select name="cutoff_date" onChange={this.onChange} value={this.cutoff_date} className="form-control custom-select">
                                                    <option value="">--Elige una fecha de corte</option>
                                                    {this.cutoff_dates && this.cutoff_dates.map((option: any, index: any) => {
                                                        return (
                                                            <option value={option.date} key={index}>{option.date}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <select name="type" onChange={this.onChange} value={this.type} className="form-control custom-select">
                                                <option value="">--Elige un tipo de recibo</option>
                                                {this.types && this.types.map((option: any, index: any) => {
                                                    return (
                                                        <option value={option.id} key={index}>{option.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <select name="payment_status" onChange={this.onChange} value={this.payment_status} className="form-control custom-select">
                                                <option value="">--Elige un estatus</option>
                                                {this.status && this.status.map((option: any, index: any) => {
                                                    return (
                                                        <option value={option.id} key={index}>{option.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group bmd-form-group">
                                            <select name="payment_type" onChange={this.onChange} value={this.payment_type} className="form-control custom-select">
                                                <option value="">--Elige un tipo de pago</option>
                                                {this.payment_types && this.payment_types.map((option: any, index: any) => {
                                                    return (
                                                        <option value={option.id} key={index}>{option.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        Desde: <DatePicker selected={this.fromDate} onChange={date => this.setStartDate(date, "from")} />
                                    </div>
                                    <div className="col-sm-6">
                                        Hasta: <DatePicker selected={this.toDate} onChange={date => this.setStartDate(date, "to")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}